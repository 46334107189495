export default {
	crypto: {
		title: { "0": "Acquista cripto", "1": "Acquista cripto", "2": "C2C" },
		list: {
			"0": "Trading C2C",
			"1": "Compra",
			"2": "Vendi",
			"3": "Inserzionisti (tasso di completamento)",
			"4": "Negozi",
			"5": "Prezzo",
			"6": "Disponibile",
			"7": "Limite",
			"8": "Metodo di pagamento",
			"9": "Commercio",
			"10": "L'inserzionista può porre restrizioni sulle transazioni sull'annuncio e vedrai il motivo della restrizione dopo aver effettuato l'accesso.",
		},
		buy: {
			"0": "Acquista cripto",
			"1": "Vendi criptovalute",
			"2": "Storia",
			"3": "Supporto",
			"4": "Pagare",
			"5": "Ricevi",
			"6": "Seleziona il metodo di pagamento",
			"7": "Est. orario di arrivo",
			"8": "TASSE",
			"9": "Dettagli dell'ordine",
			"10": "Metodo di pagamento",
			"11": "Per saperne di più",
			"12": "Prezzo",
			"13": "Totale (tassa inclusa)",
			"14": "Disclaimer",
			"15": "Stai lasciando HKDFinanc e sarai reindirizzato a Mercuryo, un istituto finanziario autorizzato che fornisce servizi di pagamento con carta di credito/debito come terza parte. Qualsiasi rischio che si verifichi durante l'utilizzo del servizio sarà a carico di Mercuryo. Si prega di leggere e accettare i Termini di utilizzo prima di procedere.",
			"16": "Ho letto e accetto le Condizioni d'uso",
			"17": "You will get",
			"18": "Invia",
			"19": "Inserisci l'importo",
			"20": "Saldo insufficiente del conto",
			"21": "Disponibile",
			"22": "Conto spot disponibile",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		},
	},
	common: {
		confirm: "Definire",
		cancel: "Cancellazioni",
		tip: "Attirare l'attenzione su qcs.",
		place_select: "Selezionare",
		submit: "Presentare (un rapporto, ecc.)",
		more: "Di più",
		deposit: "Deposito",
		real_trade: "Un affare reale",
		paper_trade: "Trading demo",
		build_paperTrade: "Creare un conto demo",
		start_trade: "Iniziare a fare trading ora",
		app_name: "HKDFINANC",
		success: "Successi！",
		dataSources_name: "Trading Central",
		reset: "Riproposizione",
		iknow: "Preso!",
		noData: "Nessun dato disponibile",
		modify: "Modifiche",
		pingcang: "Chiudere una posizione",
		all: "Completo",
		placeEnter: "Inserire",
		countryCode: "Codice paese/area",
	},
	currency: {
		btcusdt: "Bitcoin (moneta virtuale)",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (moneta virtuale)",
		xrpusdt: "Ripple (valuta)",
		adausdt: "Valuta Ada (usata in frazioni di valuta)",
		eosusdt: "Moneta Pomelo",
		dotusdt: "Boca Raton (precedentemente nota come Boca Raton), valuta russa",
		trxusdt: "Moneta per terreni agricoli a onde (informatica)",
		xmrusdt: "Moneta Monroe (ad es. dollaro USA)",
		dogeusdt: "Moneta per cani",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Dollaro australiano Dollaro USA",
		fx_sgbpusd: "Sterlina-dollaro",
		fx_seurusd: "Eurodollaro",
		fx_snzdusd: "Dollaro neozelandese (NZD) Dollaro statunitense",
		fx_susdcad: "Dollaro USA-dollaro canadese",
		fx_susdchf: "Dollaro USA Franco svizzero",
		fx_susdjpy: "Dollaro USA-yen",
		fx_saudcad: "Dollaro australiano Dollaro canadese",
		fx_saudchf: "Dollaro australiano e franco svizzero",
		fx_saudjpy: "Dollaro australiano Yen giapponese",
		fx_saudnzd: "Dollaro australiano Dollaro neozelandese (NZD)",
		fx_scadchf: "Dollaro canadese Franco svizzero",
		fx_scadjpy: "Dollaro canadese Yen giapponese",
		fx_schfjpy: "Franco svizzero yen",
		fx_seuraud: "Euro Dollaro australiano",
		fx_seurcad: "Euro Dollaro canadese",
		fx_seurgbp: "Euro Sterlina",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Dollaro neozelandese (NZD)",
		fx_sgbpaud: "Sterlina inglese Dollaro australiano",
		fx_sgbpchf: "Sterlina Franco svizzero",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Sterlina Dollaro neozelandese (NZD)",
		fx_scadnzd: "Dollaro canadese Dollaro neozelandese",
		hf_CL: "Petrolio greggio WTI New York",
		hf_OIL: "Petrolio greggio Brent",
		hf_GC: "Oro di New York",
		hf_SI: "Argento di New York",
		hf_HG: "Rame (parola d'ordine)",
		hf_NG: "Stati Uniti Gas naturale",
		hf_CAD: "Rame (parola d'ordine)",
		hf_AHD: "Alluminio di Londra",
		HX_AAPL: "Melograno",
		HX_BABA: "Ali Baba, personaggio de Le mille e una notte",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (rivenditore)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), inventore e ingegnere serbo",
		HX_N225: "Nikkei 225日经225",
		HX_GDAXI: "DAX30, Germania",
		HX_IBEX: "IBEX35, Spagna",
		HX_SPX: "Indice S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Corea",
		HX_NZ50: "Nuova Zelanda 50",
		HX_PSI20: "Portogallo PSI20",
		hf_ZSD: "Zinco di Londra",
		hf_NID: "Nichel di Londra",
		hf_PBD: "Piombo di Londra",
		hf_SND: "Stagno di Londra",
		hf_XAU: "Oro di Londra (oro spot)",
		hf_XAG: "Argento di Londra (argento spot)",
		hf_XPT: "Futures sul platino",
		hf_XPD: "Futures sul palladio",
		hf_CT: "Cotone USA",
		hf_SM: "Fagioli di soia in polvere",
		hf_BO: "Olio di soia USA",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, Francia",
		HX_SX5E: "Snooker europeo 50",
		HX_ICEXI: "ICEX, Islanda",
		HX_ASE: "ASE Atene, Grecia",
		HX_OMXC20: "OMX Copenaghen 20",
		HX_OSEBX: "OSEBX, Norvegia",
		HX_OMXSPI: "OMXSPI, Svezia",
		HX_AMD: "Semiconduttore Chaowei",
		HX_DIS: "Disney (nome)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (marchio)",
		HX_NVDA: "NVIDIA",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Tecnologia Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	addnew: {
		paypass: "Password di trading",
		loginpassTit: "Modifica della password",
		paypassTit: "Modificare la password del bancomat",
		paypassTit2: "La password sociale è inizialmente la password di accesso.",
		buyUp: "Innalzarsi",
		buyDown: "Acquistare e vendere",
		addressName1: "China Gold Financial (International) Holdings Limited",
		addressValue1: "Stanza 1101, 11° piano, Koon Kwan Mansion (ex Citibank Tower), 3 Garden Road, Central, Hong Kong",
		addressName2: "Hong Kong Zhong Cai Financial Investment Company Limited",
		addressValue2: "Centro Zhongcai, 131-133 Queen's Road Central, Central, Hong Kong",
		addressName3: "Po Sun Financial Holdings Limited",
		addressValue3: "Stanza 1908, 19° piano, 89 Queensway, Admiralty, Hong Kong Lippo Centre Tower 2",
		loan: "Ottenere un prestito (ad esempio da una banca)",
		repaid: "Restituito",
        unpaid: "Mancato pagamento",
		loanMore: "Voglio un prestito.",
		immediateRepayment: "Rimborso immediato",
		accountType1: "Conto Moneta",
		accountType2: "Conto contrattuale",
		accountType3: "Conto opzioni",
		from: "Attraverso (un varco)",
        to: "Fino a quando",
		get: "Otterrà",
		labelBreed: "Tipo di valuta",
		placeBreed: "Selezionare la valuta",
		labelTransAccount: "Conto di trasferimento",
		labelAmount: "Importo trasferito",
		placeAmount: "Inserire l'importo del trasferimento",
		transferTit:"Trasferimenti di conto",
		convertTit:"Scambio di monete flash",
		balanceTit:"Saldo del conto",
		available: "Credito disponibile",
        pending: "Bloccare ",
        equivalent: "Importo a",
		coinTrade: "Commercio di monete",
		secondContract: "Secondo contratto (nello sport)",
		number: "Quantità",
		labelNumber: "Quantità",
		placeNumber: "Inserire il numero di riscatti",
		time: "Tempi",
		loadAmount: "Importo previsto del prestito",
		repaymentCycle: "Ciclo di rimborso del prestito",
		dailyRate: "Tasso di interesse giornaliero",
		repaymentMethod: "metodo di rimborso",
		loanTip: "Prestiti di credito (assicurarsi che le foto siano ben visibili)",
		loanTip1: "Caricare il certificato di proprietà",
        loanTip2: "Prova di reddito (rapporto di lavoro)",
        loanTip3: "Dettagli dell'estratto conto bancario",
        loanTip4: "Caricare la foto frontale della carta d'identità",
        interest: "Interesse ",
		repaymentMethod1: "con obbligo di rimborso in un'unica soluzione",
	},
	params: {
		product_foreign: "Cambio di valuta estera",
		product_shop: "Merce",
		product_number: "Indice",
		product_stock: "Stock (mercato)",
		product_coin: "Crittografato",
		billTypes: [
			{v: 101, name: 'Ricarica '},
			{v: 102, name: 'Deposito '},
			{v: 103, name: 'Congelamento'},
			{v: 104, name: 'Scongelare'},
			{v: 105, name: 'Deposito'},
			{v: 106, name: 'Deposito'},
			{v: 201, name: 'Blocco dei prelievi'},
			{v: 202, name: 'Ritirare i fondi'},
			{v: 203, name: 'Ritiro riuscito'},
			{v: 204, name: 'Mancato ritiro dei fondi'},
			{v: 205, name: 'Tassa di ritiro'},
			{v: 206, name: 'Trasferimento'},
			{v: 207, name: 'Passare a'},
			{v: 208, name: 'Trasferimento di monete'},
			{v: 209, name: 'Trasferimento di monete'},
			{v: 301, name: 'Commissione di gestione del contratto'},
			{v: 302, name: 'Reddito contrattuale'},
			{v: 303, name: 'Perdita del contratto'},
			{v: 304, name: 'Margine del contratto'},
			{v: 305, name: 'Margine di rendimento del contratto'},
			{v: 311, name: 'Acquisto di opzioni'},
			{v: 312, name: 'Plusvalenza su opzione'},
			{v: 313, name: 'Opzione Rendimenti'},
			{v: 314, name: 'Commissione di gestione dell"opzione'},
			{v: 315, name: 'Blocco degli acquisti di monete'},
			{v: 316, name: 'Detrazione per l"acquisto di monete'},
			{v: 317, name: 'Rendimenti degli acquisti di monete'},
			{v: 318, name: 'Acquisto di monete sul conto'},
			{v: 319, name: 'Acquisto di monete sul conto'},
			{v: 320, name: 'Blocco della vendita di monete'},
			{v: 321, name: 'Deduzione per la vendita di monete'},
			{v: 322, name: 'Rendimenti della vendita di monete'},
			{v: 323, name: 'Moneta venduta al conto'},
			{v: 324, name: 'Moneta venduta al conto'},
			{v: 325, name: 'Tassa di gestione delle monete'},
			{v: 401, name: 'I minatori si uniscono'},
			{v: 402, name: 'Ritorno del minatore'},
			{v: 403, name: 'Ricavi da macchine da miniera'},
			{v: 404, name: 'Uscita macchina mineraria'},
			{v: 405, name: 'Tassa di uscita dalle miniere'},
			{v: 411, name: 'Proventi da prestiti'},
			{v: 412, name: 'Commissione di prestito'},
			{v: 413, name: 'Rimborso riuscito'},
        ],
	},
	message: {
		logout: "Disconnettersi",
		register_success: "Registrazione riuscita",
		place_inner_personal: "Inserire prima le informazioni personali",
		submit_success: "Inviato con successo！",
		copy_success: "Copiare il successo！",
		copy_error: "Fallimento della riproduzione！",
		modify_success: "Modificato con successo",
		no_balance: "Saldo insufficiente a coprire il deposito cauzionale",
		palce_password: "Inserire la password",
		trade_success: "Transazione riuscita",
		placeEnterAmount: "Inserire l'importo",
		tipTit1: "È sicuro di voler pagare questo ordine?",
	},
	web: {
		account: {
			menu1: "Informazioni sul conto",
			menu2: "I miei beni",
			menu3: "Rapporti sul conto",
			menu4: "Sicurezza del conto",
			menu5: "Notifiche",
			menu6: "Centro attività",
			menu7: "Il mio portafoglio.",
			menu8: "Prelevare denaro",
			walletAdd: "Aggiungi un nuovo portafoglio",
			report: {
				history_tit: "Rapporto sulla cronologia delle transazioni",
				stream_tit: "Rapporto sul flusso di fondi",
				desc: "Selezionare l'intervallo di date appropriato per esportare il rapporto sul conto. (Avrete accesso alle informazioni sul conto per l'ultimo anno).",
				select_date: "Selezionare l'intervallo di date",
				week: "La scorsa settimana",
				month: "Circa un mese",
				threeMonth: "Ultimi tre mesi",
				sixMonth: "Gli ultimi sei mesi",
				time: "Tempi",
				view_report: "Relazione sullo spettacolo",
				email_send: "Inviato per e-mail",
				dialog_tit: "Rapporti sul conto",
				dialog_p1: "HKDFINANC Holding Ltd è registrata nelle Isole Cayman (numero SIB: 1612446) e tratta le vostre transazioni come entità commerciale.",
				tit2: "Relazione sulla storia di trading di Mitade",
				tit4: "Rapporto sul flusso di fondi Mitade",
				tit3: "Informazioni sul conto",
				label1: "Nome del conto",
				label2: "ID conto",
				label3: "Valuta del conto",
				label4: "Tempo di segnalazione",
				label5: "Periodo di riferimento",
				label6: "Flusso di cassa",
				empty: "Non ci sono operazioni chiuse",
				email_des: "Il rapporto verrà inviato al vostro indirizzo e-mail",
				tab1: "Segnalazione",
				tab2: "Dichiarazione giornaliera",
				tab3: "Estratto conto mensile",
			},
			account: {
				tit1: "Benvenuti a HKDFINANC",
				tit2: "Verifica completa dell'identità per operare con depositi reali",
				p1: "Aprire un conto di trading live",
				p2: "Autenticazione",
				btn1: "Autenticazione",
				tit3: "Informazioni personali",
				label_name: "Nome e cognome",
				label_email: "Indirizzo e-mail",
				label_address: "Indirizzo di residenza",
				tit4: "Informazioni sul conto",
				label_account: "Conto di trading (transazione reale)",
				label_currency: "Valuta base (transazioni reali)",
				label_company: "Conto corrente Società",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd HKDFINANC Group of Companies è autorizzata dalla Cayman Islands Monetary Authority (CIMA).",
				tit5: "Aprire un conto di trading live",
				tit6: "Miglioramento delle informazioni",
				tit7: "Compilare le informazioni di base",
				tit8: "Valutazione del rischio",
				tit9: "Valutare la tolleranza al rischio di trading",
				tit10: "Autenticazione",
				tit11: "Completamento della verifica dell'identità in conformità ai requisiti normativi.",
				p3: "Selezionare uno dei seguenti documenti di supporto per la verifica",
				type1: "Carte d'identità",
				type2: "Patente di guida",
				type3: "Passaporto",
				p4: "Confermo che tutte le informazioni e i documenti forniti sono completi, veritieri e accurati e mi impegno a fornire informazioni aggiornate a HKDFINANC entro 30 giorni in caso di aggiornamenti.",
				next: "Il passo successivo",
				btn2: "Accreditamento superato",
				modify_personal_tit: "Modifica delle informazioni personali",
			},
			activity: {
				tit: "Centro attività",
				desc: "È possibile partecipare ad attività per ricevere premi che incrementano il reddito da investimento.",
			},
			capital: {
				real_trade: "Un affare reale",
				label_nowAccount: "Conto corrente",
				label_tradeAccount: "Numero di conto di trading",
				label_currency: "Valuta di base",
				deposit_withdraw_record: "Registri dei depositi e dei prelievi",
				capital_flow_details: "Dettagli del flusso di fondi",
			},
			notification: {
				tit: "Impostazioni di notifica",
				tit1: "Aprire canali di notifica",
				email: "Indirizzo e-mail",
				message: "Messaggi di testo",
				push: "Spingere",
				verify: "Convalidare (una teoria)",
				verified: "Convalidato",
				p1: "Aprite/verificate i seguenti canali di notifica per tenere traccia delle novità più importanti!",
				type1: "Mercato",
				tit2: "Notifica delle attività di marketing",
				desc2: "Informazioni sulle offerte speciali della piattaforma, attività operative e altre notizie.",
				tit3: "Istruzioni per l'uso",
				desc3: "Materiale di conoscenza ricco e specializzato",
			},
			security: {
				tit: "Crittografico",
				isSet: "Configurato",
				set: "Procedere con l'impostazione",
				verify: "Convalidare (una teoria)",
				verified: "Convalidato",
				tit1: "Certificazione di sicurezza",
				email: "Indirizzo e-mail",
				phone: "Numero di telefono cellulare",
				phone_tit: "Numero di telefono cellulare vincolato",
				password_tit: "Cambiare la password",
			},
		},
		market: {
			hot: "In voga",
			prev: "Pagina precedente",
			next: "Pagina successiva",
			line: "Condivisione del tempo",
			main_subplot: "Indicatori del grafico principale e sussidiario",
			main: "Indicatore grafico principale",
			subplot: "Indicatore grafico",
			go_trade: "Passa alla pagina della transazione",
		},
		news: {
			tip: "Disclaimer: le informazioni di cui sopra sono informazioni generali di riferimento e non devono essere utilizzate come base per decisioni di trading.",
		},
		trade: {
			state1: "Partecipazioni",
			state2: "Ordine pendente",
			state3: "Storie",
			state: "Stato di fatto",
			state4: "Posizione chiusa",
			state5: "Ritirato",
			type: "Tipologia",
			type1: "Completo",
			type2: "Reach ",
			type3: "Buy-in",
			left_label1: "Self-service",
			left_label2: "Visti di recente",
			left_type1: "Modello colonnare compatto",
			left_type2: "Modello colonnare sciolto",
			left_type3: "Mostra grafico",
			all: "Completo",
			right_bp: "Il raccolto di ieri",
			right_sp: "Giorno d'oggi",
			right_height: "Supremo",
			right_low: "Più basso",
			remind_tit: "Nuovi avvisi",
			remindList: "Elenco dei promemoria",
			remind_btn: "Nuovi avvisi",
			right_tab1: "Rapporti",
			right_tab2: "Particolari",
			right_sell: "Reach ",
			right_buy: "Buy-in",
			right_sell1: "Reach ",
			right_buy1: "Buy-in",
			right_type: "Tipologia",
			right_type1: "Listino prezzi di mercato",
			right_type2: "Ordine pendente",
			right_priceMoney: "Prezzo dell'ordine pendente",
			right_pointerNumber: "Numero (lotti)",
			right_lever: "Barra di trazione",
			right_balance: "Saldo disponibile",
			right_stop_profit: "Approfittate.",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Cera e calante",
			now_order: "Ordina ora",
			confirm_order: "Confermare l'ordine",
			right_tit1: "Strategia di trading",
			right_rinei: "In pochi giorni",
			right_short: "A breve termine",
			right_middle: "Medio termine",
			right_time: "Tempo di rilascio",
			right_tit2: "Strategia alternativa",
			right_tit3: "Revisione tecnica",
			right_tit4: "Numeri",
			right_tit5: "Umore di trading",
			right_label_sell: "Venditore (di beni)",
			right_label_buy: "Acquirente",
			right_tip: "A titolo puramente informativo, non costituisce una posizione della Divisione.",
			right_tip2: "Aggiornato ogni 15 minuti",
			right_tit6: "Variazione del prezzo",
			minute: "Minuti",
			today: "Al momento",
			right_tit7: "Intervallo di variazione del prezzo",
			now_price: "Prezzo corrente",
			right_low_price: "Prezzo più basso",
			right_height_price: "Prezzo più alto",
			right_tit8: "Informazioni sul contratto",
			right_tit9: "AUD/CHF",
			right_label1: "Numero di transazioni singole",
			right_label2: "Leva massima",
			right_label3: "Dimensione del contratto",
			right_label4: "Numero massimo di posizioni totali",
			right_label5: "Spread fluttuante",
			right_label6: "Carica notturna",
			hand: "Persona esperta in determinati tipi di lavoro",
			right_collection_time: "Tempo di raccolta",
			right_tip3: "Base delle commissioni: dalla dimensione complessiva della transazione",
			right_label7: "Rapporto di margine",
			right_label8: "Rapporto di margine di manutenzione",
			right_label9: "Orari di negoziazione",
			right_label10: "Sessione di trading corrente",
			right_label11: "Prossima sessione di trading",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Transazione della piattaforma",
		banner_p3: "Forex, Materie prime, Indici, Azioni",
		banner_p4: "E prodotti finanziari più popolari",
		banner_p5: "Potete esplorare i mercati finanziari più caldi del mondo. ",
		hot: "In voga",
		tit1: "Transazioni più amichevoli",
		p1: "Interfaccia semplice e intuitiva per un facile utilizzo",
		p2: "Supporto web, app mobile e desktop, trading a portata di mano",
		p3: "Strumenti di gestione del rischio quali stop loss/trailing stop",
		p4: "Grafici tecnici multipli e calendari finanziari, notizie in tempo reale",
		p5: "Notifiche in tempo reale via e-mail, SMS e push",
		p6: "Miglioramento continuo per una migliore esperienza di trading",
		btn1: "Esplora le nostre piattaforme",
		row3_tit: "Scambiate liberamente in qualsiasi momento e ovunque",
		row3_tip: "Supporto web, app mobile e desktop, trading a portata di mano",
		row3_tit2: "Scansione del codice per il download",
		row3_tit3: "Desktop.",
		row3_tit4: "Mobile",
		regular_tit: "Piattaforma internazionale affidabile",
		regular_tip: "Ci impegniamo a fornire un ambiente commerciale sicuro e responsabile ai nostri clienti.。",
		regular_tit1: "Regolamentati da organizzazioni autorevoli",
		regular_p1: "Regolamentati e autorizzati dalle autorità del settore e fidati dai clienti di tutto il mondo",
		regular_tit2: "Proteggere la sicurezza finanziaria",
		regular_p2: "I depositi dei clienti retail sono segregati in conti fiduciari in base alle necessità, in conformità ai requisiti normativi.",
		regular_tit3: "Protezione del saldo negativo",
		regular_p3: "Fornite la protezione del saldo negativo del conto, il saldo negativo in modo tempestivo per cancellare lo zero, in modo che la vostra perdita non superi l'importo delle transazioni di ingresso, la pace della mente.",
		regular_tit4: "Servizio clienti 7*24 ore",
		regular_p4: "Team di assistenza clienti sincero e professionale, 24 ore di supporto online, felice di risolvere qualsiasi problema che avete!",
		service_tit: "Altri servizi per aiutarvi",
		service_tit1: "Strategia di trading",
		service_p1: "Strategie di trading in tempo reale per aiutarvi a comprendere le ultime tendenze del mercato e a capire meglio i tempi del trading.",
		service_tit2: "Conoscenza del trading",
		service_p2: "Imparate a fare trading gratuitamente con HKDFINANC e migliorate le vostre capacità di trading.",
		service_tit3: "Gestione del rischio",
		service_p3: "Scoprite gli strumenti gratuiti di gestione del rischio offerti da HKDFINANC per proteggere meglio i vostri beni.",
		step_tit: "Facile e conveniente aprire un conto",
		step_tip: "Tre semplici passi per aprire un conto in pochi minuti",
		step_tit1: "Iscrizione",
		step_p1: "Compilare le informazioni e inviare la domanda",
		step_tit2: "Deposito",
		step_p2: "Deposito rapido di fondi attraverso una varietà di metodi",
		step_tit3: "Inizio dell'attività commerciale",
		step_p3: "Scoprire le opportunità di trading e piazzare rapidamente gli ordini",
		award_tit: "I nostri risultati",
		award_p1: "Abbiamo sempre perseguito l'eccellenza e ci siamo impegnati a fornire ai nostri clienti servizi di transazione di qualità.",
		award_p2: "Il marchio HKDFINANC è onorato di ricevere questo prestigioso premio da una prestigiosa istituzione del settore, che riconosce i continui sforzi e l'impegno del team nei confronti dei nostri clienti.",
		row7_tit: "Informazioni finanziarie globali in tempo reale",
		row7_tit1: "Notizie in tempo reale",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Fare clic per scaricare",
		p1: "Negoziare in qualsiasi momento e ovunque",
		p2: "Fare trading in modo più veloce e intelligente",
		p3: "0 Commissioni di negoziazione",
		p4: "L'investimento più caldo del mondo",
		renderDom1: "Cambio di valuta estera",
		renderDom2: "Mercato azionario statunitense",
		renderDom3: "Platino",
		renderDom4: "Petrolio greggio",
		renderDom5: "Esponenti",
		renderDom6: "Criptovaluta",
		login: "Accedi",
		elail: "Casella di posta elettronica",
		phone: "Numero di telefono cellulare",
		no_account: "Nessun conto？",
		go_register: "Vai alla registrazione",
		register: "Iscrizione",
		now_register: "Registrati ora",
		have_account: "Avete già un account？",
		go_login: "Vai al login",
	},
	about: {
		serviceFee: "Tassa di servizio",
		introduction: "Introduzione alla piattaforma",
		trade_platform: "Piattaforma di trading",
		complaint_process: "Processo di reclamo",
		page: "Pagina web",
		place_search: "Cercare ciò che si vuole sapere",
		no_answer: "Non riuscite a trovare la risposta che vi serve?",
		contact_us: "Contatto",
		awards: {
			h1: "Premi e onorificenze",
			p1: "Nella nostra costante ricerca dell'eccellenza e nella dedizione a fornire ai nostri clienti servizi transazionali di qualità, il marchio HKDFINANC è onorato di essere stato premiato da una prestigiosa organizzazione del settore, che riconosce i continui sforzi e l'impegno del team nei confronti dei nostri clienti.",
			p2: "Oppure provate subito la nostra pluripremiata piattaforma di trading online!",
			name1: "Miglior Brokeraggio in America Latina",
			name2: "Miglior broker di CFD",
			name3: "Miglior Broker Fintech",
			name4: "Le 10 imprese più influenti nel 2021",
			name5: "Broker più trasparente",
			name6: "Premi Fintech Definitivi",
		},
		charges: {
			h1: "Tasse e oneri",
			p1: "Come parte integrante del miglioramento dell'esperienza del cliente, HKDFINANC vuole fornire ai propri clienti un servizio di trading più conveniente. Ci sforziamo di essere aperti e trasparenti nelle nostre commissioni e spese, senza spese nascoste. I principali costi di transazione per i clienti sono lo spread denaro/lettera e gli interessi sulle posizioni overnight.",
			h2: "In primo luogo, è possibile godere dei seguenti vantaggi quando si opera con HKDFINANC",
			li1: "Preventivo istantaneo del prodotto",
			li2: "Indicatori professionali e tecnici",
			li3: "Analisi di mercato esclusiva",
			free: "Freeware",
			view: "Guardare in alto",
			h3: "Spread denaro-lettera",
			p2: "HKDFINANC applica uno spread denaro/lettera come compenso per i suoi servizi. Questa commissione si riflette nella quotazione del prodotto e il cliente paga effettivamente lo spread quando apre una posizione. Poiché il costo dello spread non è fisso, si consiglia di accedere alla pagina di quotazione del prodotto specifico o di collegarsi al sito web di HKDFINANC.",
			h4: "Spese di deposito e prelievo",
			p3: "Al fine di ridurre al minimo i costi per i nostri clienti, nella maggior parte dei casi non vi addebitiamo alcun costo per l'accesso ai vostri fondi. Tuttavia, una società o una banca terza potrebbe addebitarvi una commissione per l'esecuzione di un deposito o di un prelievo, ad esempio una commissione bancaria di transito.",
			p4: "Per ulteriori informazioni sulle spese, contattare il nostro servizio clienti.。",
			p5: "Ad esempio, attraverso transazioni internazionali con carta di credito, trasferimenti da/verso conti bancari o transazioni in valute non supportate (conversioni di valuta estera), ecc.",
			h5: "Costi di pernottamento",
			p6: "Se alle 22:00 GMT (22:00 GMT) la posizione è ancora in corso, vi verrà addebitata una commissione overnight. Le richieste di informazioni sulle commissioni overnight per ciascun prodotto possono essere effettuate sulla pagina di quotazione del prodotto specifico o accedendo alla pagina",
			h6: "Altri costi",
			p7: "Nessun altro costo. La nostra politica di addebito è completamente trasparente e qualsiasi voce che richieda un addebito sarà annunciata e comunicata in anticipo, quindi non c'è bisogno di preoccuparsi di addebiti nascosti."
		},
		contact: {
			h1: "Contatto",
			address: "Indirizzo",
			custom_email: "E-mail di contatto con il cliente",
			business_email: "Cooperazione commerciale Email",
			h2: "Scrivici.",
			p1: "Se avete domande sui nostri servizi, compilate il modulo sottostante per sottoporci la vostra domanda e uno dei nostri specialisti del servizio clienti vi risponderà il prima possibile. In alternativa, per abbonarsi alle nostre strategie di trading giornaliere fornite in collaborazione con Trading Central, si prega di selezionare 'Abbonamento alle analisi di mercato giornaliere' nel tipo di modulo sottostante e di compilare la versione linguistica che si desidera ricevere e noi provvederemo a registrarvi. Il servizio di abbonamento è disponibile solo per i clienti reali di HKDFINANC.",
			h3: "Procedure di rappresentanza",
			p2: "Tutti i reclami saranno esaminati e risolti dal nostro team specializzato, per maggiori dettagli si rimanda a",
		},
		faq: {
			h1: "Problemi comuni",
			tit1: "A cosa serve un conto demo？",
			tit2: "Come aprire un conto live？",
			tit3: "Come creare un ordine di mercato？",
			tit4: "Perché non posso effettuare un ordine?？",
			p1: "I conti demo sono per lo più identici ai conti reali in termini di interfaccia di trading, dati e operazioni. I conti demo contengono 50.000 dollari di fondi virtuali e sono progettati per consentire ai clienti di familiarizzare con le caratteristiche della piattaforma attraverso operazioni simulate senza rischiare i propri fondi.",
			p2: "Per aprire un conto live, seguire i seguenti passaggi：",
			p3: "1. Accedere alla pagina di registrazione di HKDFINANC, seguendo le istruzioni: registrare la casella di posta elettronica/numero di cellulare, impostare la password di accesso, inviare la conferma e creare automaticamente un [Conto demo].",
			p4: "2. Nella pagina del Conto demo, selezionare 'Passa al Conto reale' e seguire la procedura per aprire un 'Conto reale",
			p5: "Prima di aprire un conto live, vi consigliamo di leggere e comprendere le informazioni legali associate al trading.",
			p6: "Si prega di notare che è possibile depositare sul proprio conto live solo dopo aver completato la verifica dell'identità.",
			p7: "Cliccando su 'Trade' (commercio) si visualizzano tutti i prodotti disponibili per il trading sulla piattaforma; è possibile inserire il codice o il nome del prodotto nella casella di input a destra per cercarlo, selezionare un prodotto e cliccare su 'SELL/BUY' (vendita/acquisto) per visualizzare la finestra di trading, in cui è possibile vedere il prezzo corrente e il margine stimato richiesto. È possibile regolare manualmente il numero di operazioni da aprire, impostare lo stop-loss e il take-profit per il controllo del rischio, quindi cliccare su 'SELL/BUY' per completare l'apertura della posizione. Si noti che i prezzi di tutti i prodotti finanziari fluttuano e si aggiornano con il mercato in qualsiasi momento e che il mercato potrebbe essere cambiato prima che si prema il pulsante ACQUISTA/VENDITA.",
			p8: "Prima di tutto, verificare che la connessione di rete sia normale. Quando il mercato delle specie di trading è temporaneamente chiuso la quotazione o chiuso causerà l'impossibilità di effettuare ordini. Si prega di contattare il nostro servizio clienti online per ulteriore assistenza.",

		},
		fundSecurity: {
			h1: "Sicurezza finanziaria",
			p1: "HKDFINANC è autorizzata e regolamentata da autorevoli enti normativi. La conformità è al centro di tutto ciò che facciamo e della nostra cultura. La sicurezza del vostro denaro è fondamentale e tutti i depositi dei clienti sono segregati.",
			p2: "I depositi dei clienti retail sono tenuti separatamente in conti fiduciari, se necessario, in conformità ai requisiti normativi.",
			p3: "HKDFINANC non utilizza i fondi dei clienti per attività commerciali diverse da quelle consentite dalle leggi e dai regolamenti.",
			p4: "HKDFINANC non speculerà in nessun mercato.",
			p5: "Revisioni indipendenti da parte di società contabili terze",
		},
		help: {
			h1: "Benvenuti nel Centro di assistenza HKDFINANC",
		},
		media: {
			h1: "Centro Media",
			p1: "Visualizza le informazioni, i comunicati stampa, le caratteristiche dei media, gli annunci e altre informazioni relative al marchio HKDFINANC.",
		},
		mitrade: {
			h1: "Informazioni su HKDFINANC",
			view_certificate: "(Metodi di identificazione)",
			p1: "HKDFINANC è una società fintech autorizzata e regolamentata dalle autorità, che si concentra sull'offerta agli investitori di un'esperienza di trading semplice e conveniente. L'innovativa piattaforma di trading diversificata di HKDFINANC ha vinto ripetutamente premi come Miglior piattaforma di trading mobile e Brokeraggio più innovativo.",
			p2: "Attraverso la piattaforma HKDFINANC, è possibile investire e fare trading in una gamma più ampia di mercati finanziari internazionali, che coprono azioni, indici, materie prime, cambi e centinaia di altre specie popolari. Oltre alle piattaforme di trading mobili e web di facile utilizzo, HKDFINANC vi offre anche costi di trading competitivi, un'esecuzione rapida delle operazioni, un eccellente servizio clienti e un'ampia gamma di informazioni di supporto, aiutandovi a cogliere tempestivamente le opportunità di investimento e di trading.",
			p3: "HKDFINANC è strettamente regolamentata da un'autorità di settore, multi-agenzia. HKDFINANC International è autorizzata e regolamentata dalla Financial Services Commission (FSC) di Mauritius con il numero di licenza GB20025791. Per i dettagli della licenza di Mauritius, è possibile consultare il sito ufficiale della FSC all'indirizzo https://www. fscmauritius.org",
			p4: "HKDFINANC Global è titolare di una licenza per servizi finanziari (AFSL 398528) autorizzata dall'Australian Securities and Investments Commission (ASIC). Per ulteriori informazioni sulle licenze australiane, visitate il sito web dell'ASIC all'indirizzo www.asic.gov.au.",
			p5: "HKDFINANC è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con la SIB Licence No. 1612446. Per ulteriori informazioni sulle licenze delle Cayman, visitate il sito ufficiale della CIMA all'indirizzo www.cima.ky.",
			p6: "Tutte le operazioni commerciali di HKDFINANC sono condotte sotto stretta sorveglianza e nel rispetto di tutte le normative.",
			h2: "Il contesto di HKDFINANC",
			p7: "HKDFINANC è composto da un team senior con ricca esperienza e conoscenza nel trading finanziario e nelle industrie fintech. La filosofia di HKDFINANC è quella di rendere il trading più facile e amichevole",
			p8: "La nostra strategia olistica e il nostro modello di business ci permettono di avere un'osservazione e una conoscenza globale e approfondita dello sviluppo del settore e della domanda del mercato, in modo da poter allocare le risorse in modo più mirato, innovare continuamente la tecnologia e ottimizzare l'efficienza, e continuare a offrire ai nostri clienti un'esperienza di trading più conveniente e amichevole. ",
			h3: "Perché HKDFINANC",
			why1: "Condizioni di trading a bassa soglia",
			why2: "Regolato dalle autorità di settore",
			why3: "Piattaforma di trading semplice e intuitiva",
			why4: "Alto livello di supporto online",
			why5: "Costi di transazione competitivi",
			why6: "Protezione del saldo negativo",
			h4: "Premi e onorificenze",
			p9: "Abbiamo sempre perseguito l'eccellenza e ci siamo impegnati a fornire ai nostri clienti servizi di transazione di qualità.",
			p10: "Il marchio HKDFINANC è onorato di ricevere questo prestigioso premio da una prestigiosa istituzione del settore, che riconosce i continui sforzi e l'impegno del team nei confronti dei nostri clienti.",
			new_add: "Nuovi utenti in tutto il mondo",
			ol1: "Miglior fornitore di notizie e analisi",
			ol2: "Il miglior broker Forex in Asia",
			ol3: "Premi australiani per la soddisfazione dei clienti del Forex",
			ol4: "Migliore applicazione mobile",
			ol5: "Il broker australiano in più rapida crescita",
			h5: "La filosofia operativa di HKDFINANC",
			tip_tit1: "Responsabilità",
			tip_tit2: "Semplice ma bello",
			tip_tit3: "Aperto (non segreto)",
			tip_tit4: "Aprire nuovi sentieri",
			tip_p1: "Tutte le operazioni di trading comportano dei rischi e HKDFINANC ha adottato ulteriori misure per fornire un ambiente di trading responsabile ai propri clienti. HKDFINANC è regolamentata dalle autorità del settore, da molteplici organizzazioni e opera con elevati livelli di sicurezza e stabilità. La nostra piattaforma continua a offrire una serie di strumenti di trading responsabile, tra cui la gestione del rischio.",
			tip_p2: "La semplicità è bellezza, il dettaglio è eccellenza, HKDFINANC si impegna per la facilità d'uso e l'esperienza dell'utente in ogni dettaglio. Continuiamo a ottimizzare la nostra piattaforma e i nostri servizi nella speranza che tutti, dal principiante all'investitore esperto, possano trarre vantaggio dalle opportunità di trading disponibili attraverso la piattaforma HKDFINANC.",
			tip_p3: "Al fine di consentire ai nostri clienti di beneficiare appieno di un ambiente di trading equo ed efficiente, HKDFINANC segue un sistema di autoregolamentazione rigoroso e trasparente per fornire una piattaforma completa e trasparente di informazioni sui prodotti, l'ambiente dei prezzi e i dati, tutte le commissioni sono chiare e aperte per garantire che l'intero processo di servizio sia completamente sincero e trasparente.",
			tip_p4: "HKDFINANC crede fermamente che il progresso tecnologico, l'innovazione finanziaria e i servizi più ampi siano gli elementi che guidano la crescita e che possiamo continuare a creare e condividere valore. A tal fine, incoraggiamo il pensiero aperto e continuiamo a guidare l'innovazione, con l'obiettivo di essere un innovatore e un leader nella tecnologia finanziaria.",
			row6_tit1: "La nostra missione",
			row6_tit2: "La nostra visione",
			row6_tit3: "Opportunità di lavoro",
			row6_p1: "Fornire a un maggior numero di persone che desiderano operare un ambiente di trading equo, efficiente e trasparente, non limitato da spazio, tempo e mercato, e guidare il settore nell'innovazione finanziaria e tecnologica.",
			row6_p2: "Crediamo che il progresso tecnologico, l'innovazione finanziaria e la diversificazione dei servizi siano le forze che guidano la nostra crescita e ci permettono di continuare a creare e condividere valore nella società.",
			row6_p3: "A tal fine, incoraggiamo l'apertura mentale e promuoviamo l'innovazione, con l'obiettivo di diventare, attraverso una crescita e un progresso continui, la",
			row6_p4: "La società di cambio leader nel mondo",
			row6_p5: "Siamo un team globale presente in 7 paesi e con un team in rapida crescita, siamo sempre alla ricerca di professionisti che si uniscano a noi. Fate ciò che amate con un team multiculturale!",
			row6_p6: "Inviateci il vostro CV aggiornato e il motivo per cui vorreste lavorare con noi:",
			row6_p7: "In alternativa, è possibile ottenere maggiori informazioni sul programma",
			row6_p8: "Forse",
			row6_p9: "Visualizza le nostre offerte di lavoro aperte su。",
			row6_p10: "HKDFINANC protegge rigorosamente la sicurezza dei vostri dati personali. HKDFINANC non vi chiederà informazioni finanziarie durante il processo di candidatura. Non richiediamo informazioni finanziarie, carte di credito, conti bancari o altre forme di pagamento per le nostre assunzioni.",
			row6_p11: "Se si sospetta che una posizione sia fraudolenta, si prega di contattarci tramite il sito web",
			row6_p12: "Contattare il Dipartimento Risorse Umane di HKDFINANC. Se siete sicuri di essere stati ingannati da una falsa offerta di lavoro, contattate il dipartimento locale dell'organizzazione di riferimento.",

		}
	},
	product: {
		row2: {
			tit: "Varietà commerciali",
			sell: "Reach (prezzo in un'asta)",
			buy: "Buy-in",
			trade: "Rapporti",
			more_product: "Altri prodotti di trading",
		},
		row3: {
			tit: "Supporta transazioni con più dispositivi",
			tit1: "Supporta transazioni con più dispositivi",
			p1: "HKDFINANC offre piattaforme di trading basate sul web, su dispositivi mobili e su desktop. Potete fare trading in qualsiasi momento e ovunque.",
			download: "Scaricare",
		},
		row4: {
			tit: "Selezionare HKDFINANC",
			tit1: "Piattaforma semplice e intuitiva",
			p1: "Integrazione di quotazioni, trading, gestione del conto, informazioni, gestione del rischio, funzioni potenti, per ottenere una migliore esperienza d'uso.",
			tit2: "Costi di transazione competitivi",
			p2: "0 commissioni di trading, spread bassi molto competitivi e trasparenti, SWAP bassi, in modo da poter godere di un trading a basso costo!",
			tit3: "Condizioni di trading a bassa soglia",
			p3: "La dimensione minima del lotto per ogni transazione è di soli 0,01 lotti e potete aprire una posizione con un margine basso.",
			tit4: "Regolato dall'autorità",
			p4: "Autorizzati e regolamentati dalle autorità del settore, tutti i depositi dei clienti sono segregati separatamente per proteggere la sicurezza dei beni dei clienti.",
			tit5: "Protezione del saldo negativo",
			p5: "Il vostro conto non perderà mai più del suo capitale in qualsiasi condizione di mercato e i saldi negativi saranno liquidati tempestivamente per migliorare le vostre capacità di gestione del rischio.",
			tit6: "Alto livello di supporto online",
			p6: "Fornire una risposta rapida al servizio clienti online, fornirà un servizio migliore attraverso gli sforzi incessanti del team di professionisti.",
		},
		row5: {
			tit: "Problemi correlati",
		},
		commodities: {
			title: "Commercio di materie prime",
			p: "Le materie prime come i metalli preziosi e l'energia sono considerate una parte importante degli investimenti diversificati, in quanto offrono protezione contro l'inflazione e opportunità di apprezzamento dell'investimento. Tra queste, l'oro, l'argento e il petrolio sono materie prime scambiate in grandi volumi e i loro prezzi aperti e trasparenti, nonché l'elevata liquidità, sono stati ampiamente accolti dagli investitori. I prezzi delle materie prime fluttuano in modo significativo a causa di fattori quali la domanda e l'offerta, le situazioni economiche e politiche e le valute, offrendo opportunità di rischio-rendimento.",
		},
		forex: {
			title: "Trading in valuta estera",
			p: "Il Forex trading è la conversione di una valuta in un'altra e il mercato dei cambi è il mercato finanziario più scambiato al mondo. Il mercato dei cambi conta un gran numero di partecipanti che vi operano per effettuare pagamenti, per coprirsi dal rischio di movimenti valutari o per realizzare un profitto. Il trading sul Forex avviene attraverso una rete di banche, istituzioni e singoli trader in tutto il mondo, con fluttuazioni dei prezzi 24 ore su 24, 5 giorni su 7, che offrono opportunità di guadagno e di rischio.",
		},
		indices: {
			title: "indices",
			p: "Un indice azionario è un valore statistico che rappresenta un particolare tipo di azione caratteristica in una determinata borsa, ed è un indicatore del livello generale dei prezzi e del movimento di un particolare tipo di azione costitutiva nel mercato. La negoziazione di indici azionari che riflettono le opportunità di investimento nel mercato generale o in un settore può ridurre il rischio di investire in singoli titoli. Gli indici più popolari nei mercati statunitense, europeo, asiatico e australiano, come l'Australia 200, l'US Tech 100, l'Hong Kong 50, il Germany 30, ecc. offrono diverse opportunità nei mercati azionari globali.",
		},
		shares: {
			title: "Transazione azionaria",
			p: "Un'azione è un titolo negoziabile emesso in borsa. Per raccogliere capitali, le società distribuiscono la proprietà dell'azienda attraverso le borse quotate in borsa utilizzando i certificati. Solo nel 2019, le contrattazioni azionarie mondiali hanno superato i 60.000 miliardi di dollari, una cifra talmente grande e liquida da rimanere uno degli strumenti finanziari più popolari agli occhi degli investitori globali, anche dopo molti anni. I prezzi delle azioni sono influenzati da una serie di fattori, tra cui la macroeconomia, le prospettive del settore e le operazioni aziendali, e sono altamente volatili, con opportunità di investimento e di trading sempre presenti.",
		},
		coins: {
			title: "Criptovaluta",
			p: "Le criptovalute sono una valuta digitale creata tramite codice. Operano autonomamente al di fuori dei sistemi bancari e governativi tradizionali. Come suggerisce il nome, le criptovalute utilizzano la crittografia per proteggere le transazioni e consentono la creazione di altre unità. Il Bitcoin è la criptovaluta originale e di gran lunga più famosa, creata da Satoshi Nakamoto e lanciata nel gennaio 2009. Ancora oggi non si sa se il nome Satoshi Nakamoto si riferisca a una persona o a un gruppo di persone. Il Bitcoin è considerato la prima criptovaluta decentralizzata. Come tutte le criptovalute, è controllata attraverso un database di transazioni blockchain, utilizzato come libro mastro pubblico distribuito. Ad oggi, ci sono più di 1.000 criptovalute disponibili per il trading online."
		}
	},
	trade: {
		data_sources: "Fonte dei dati",
		tip: "Le informazioni di cui sopra sono solo di riferimento. HKDFINANC non garantisce l'accuratezza, la tempestività o la completezza delle informazioni e l'utente non deve fare eccessivo affidamento sulle informazioni fornite. Le presenti informazioni non contengono le nostre rilevazioni dei prezzi né alcuna offerta o sollecitazione alla negoziazione di strumenti finanziari. HKDFINANC non è una società di consulenza finanziaria e fornisce solo servizi di esecuzione di ordini. Si consiglia ai lettori di richiedere una propria consulenza in materia di investimenti. Si prega di consultare il nostro disclaimer completo.",
		tip2: "Aggiornato ogni venerdì alle 15:00 (GMT)",
		tip3: "Trading Views è uno strumento di previsione del sentiment di mercato, che riflette le opinioni e le previsioni di tendenza a breve e medio termine degli esperti del settore per ogni prodotto caldo.",
		analysis: {
			tit: "Strategia di trading",
			all: "Completo",
			day: "In pochi giorni",
			tit1: "Strategia di trading",
			tit2: "Revisione tecnica",
		},
		calendar: {
			tit: "Calendario finanziario",
			prev_week: "La scorsa settimana",
			next_week: "La prossima settimana",
			place_date: "Data di ricerca",
			select: "Opzioni ",
			select1: "Nazioni",
			select2: "Significato",
			start_time: "Ora di inizio",
			end_time: "Ora di fine",
			search: "Cercare qcs.",
		},
		contract: {
			tit: "Specifiche del contratto",
			type1: "Completo",
			type2: "Cambio di valuta estera",
			type3: "Merce",
			type4: "Esponenti",
			type5: "Azioni USA",
			type6: "Azioni australiane",
			tit1: "Principali valute estere",
			tit2: "Scambi minori",
			tit3: "Merce",
			tit4: "Indici principali",
			tit5: "Indice minore",
			tit6: "Azioni USA",
			tit7: "Azioni australiane",
			tit8: "Regole generali",
			p1: "Rapporto di planarità forzata",
			p2: "Validità dell'ordine in sospeso",
			p3: "Senza limiti",
			p4: "Tempo di liquidazione delle tasse in una notte",
			p5: "Regolamento giornaliero; Estate: 05:00, Inverno: 06:00",
			p6: "Informazioni su leva finanziaria, tassi overnight, ecc.",
			p7: "Vedere Piattaforme di trading",
			p8: "Quanto sopra si applica a tutte le varietà commerciali",
			tit9: "Calendario settimanale di trading",
			p9: "Chiusura (del mercato)",
			p10: "I suddetti orari di negoziazione non tengono conto degli effetti delle festività o di particolari aggiustamenti del mercato.。",
			p11: "Raccomandazioni specifiche sull'orario di trading",
			p12: "Piattaforma di accesso",
			p13: "Consultate i profili delle varietà interessate.",
		},
		forecast: {
			tit: "Prospettive di trading",
			p1: "Valore medio",
			p2: "Mercato toro",
			p3: "Ribassista",
			p4: "Correnti incrociate",
			p5: "Tendenza",
		},
		news: {
			tit: "Notizie in tempo reale",
		},
		platforms: {
			tit: "Piattaforma di trading HKDFINANC",
			p: "Interfaccia semplice e intuitiva, eccellenti prestazioni di trading, soddisfano le esigenze di un maggior numero di investitori. Iniziate a fare trading con HKDFINANC oggi stesso.",
			tit1: "Piattaforma di trading HKDFINANC comoda e facile da usare",
			tit2: "Fate trading sempre e ovunque con l'App HKDFINANC!",
			li1: "Negoziare su centinaia di mercati popolari con una regolamentazione autorevole per garantire la sicurezza dei fondi",
			li2: "Monitorate le tendenze del mercato in tempo reale, visualizzate le analisi di trading e sfogliate le ultime notizie finanziarie.",
			li3: "Visualizzate diversi tipi di grafici di trading in qualsiasi momento e applicate le funzioni grafiche senza alcun problema.",
			li4: "Provate la pluripremiata piattaforma di trading di HKDFINANC sul vostro dispositivo Android o iOS!",
			code_download: "Scansione del codice per il download",
			tit3: "Non c'è bisogno di scaricare, si commercia online tramite il browser",
			li5: "Non è necessario scaricare nulla, fate trading online direttamente dal browser del vostro PC.",
			li6: "Premiato: Miglior Broker Forex in Asia 2022, Miglior Piattaforma di Trading in Australia 2022, Broker Forex Fintech in più rapida crescita al mondo 2022",
			li7: "Fornisce potenti indicatori tecnici: MACD, KDJ, RSI, TRIX, DMA, CCI, ecc.",
			li8: "Dotati di strumenti di gestione del rischio come lo stop-loss e la protezione del saldo negativo per mitigare il rischio potenziale delle vostre operazioni.",
			tit4: "La soluzione perfetta per le vostre transazioni su desktop",
			li9: "Visualizzare facilmente le quotazioni di mercato nell'elenco autoselezionato",
			li10: "Abilitare gli avvisi per ricevere avvisi di variazione dei prezzi e altre notifiche di informazioni",
			li11: "I layout dei grafici multipli consentono di seguire il mercato da diverse dimensioni temporali.",
			li12: "Specifica del contratto, sentiment di trading e analisi di trading in un'unica interfaccia, senza bisogno di cambiare",
			li13: "Ricevi le ultime notizie sul trading direttamente su HKDFINANC!",
			tit5: "Perché scegliere la piattaforma di trading HKDFINANC?",
			row5_p1: "Sicurezza",
			row5_p2: "0% di commissione",
			row5_p3: "Supporto multipiattaforma",
			row5_p4: "Grafico tecnico",
			row5_p5: "Accesso in qualsiasi momento e ovunque",
			row5_p6: "Vari strumenti di disegno",
		},
		quotes: {
			tit: "Preventivi in tempo reale",
			p1: "Fornisce quotazioni istantanee, sentiment di mercato e notizie relative agli strumenti finanziari più interessanti del momento.",
			hot: "In voga",
		},
		risk: {
			tit: "Gestione del rischio",
			p1: "Tutte le operazioni di trading comportano un rischio. Utilizzando gli strumenti gratuiti di gestione del rischio forniti da HKDFINANC, il rischio può essere gestito in modo efficace indipendentemente dalle condizioni di mercato.",
			function_tit1: "Funzione Take Profit/Stop Loss",
			function_tit2: "Funzione di trailing stop",
			function_p1: "Bloccare i profitti",
			function_p2: "Limitare le perdite",
			function_p3: "Massimizzare il blocco degli utili",
			function_p4: "Non è necessario monitorare automaticamente le posizioni",
			function_p5: "Adeguamento del prezzo di chiusura delle perdite",
			row2_tit1: "Funzione Take Profit/Stop Loss",
			row2_p1: "Quando si crea un nuovo ordine o si modifica un ordine esistente, è possibile impostare 'Take Profit'e Stop Loss''. Una volta impostato, l'ordine verrà normalmente chiuso al prezzo target impostato, aiutandovi a bloccare i vostri profitti quando raggiungete il vostro target o a ridurre le vostre perdite se il mercato si muove contro di voi. Si noti che qualsiasi ordine può essere cortocircuitato a causa della volatilità del mercato, nel qual caso il sistema non sarà in grado di eseguire l'ordine al prezzo predefinito, ma chiuderà la posizione al prezzo successivo più favorevole rispetto al prezzo target.",
			row2_tit2: "Esempi",
			row2_p2: "Il prezzo attuale di EUR/USD è 1,13816/1,13837 (vendere/acquistare). Aprite un ordine di acquisto per 1 lotto (1 lotto = 100.000 euro) a 1,13837 e impostate uno stop loss a 1,13806.",
			row2_p3: "In generale, quando il prezzo scende a 1,13806, l'ordine di stop loss verrà attivato e la posizione verrà chiusa a 1,13806 per una perdita combinata di 31 dollari.",
			row2_p4: "Tuttavia, quando le condizioni di mercato cambiano e il prezzo scivola direttamente da 1,13837 a 1,13795, saltando il vostro obiettivo di stop loss, il mercato salta e il sistema non sarà in grado di chiudere la posizione a 1,13806, ma chiuderà la posizione per voi al prezzo successivo più favorevole, cioè 1,13795, e la perdita finale sarà di 42 USD.",
			row3_tit1: "Funzione di trailing stop",
			row3_tit2: "Esempi",
			row3_p1: "I trailing stop (noti anche come trailing stop) sono una potente funzione che consente di bloccare i profitti o minimizzare le perdite senza dover monitorare costantemente le posizioni. Quando si crea un ordine, è sufficiente impostare un trailing stop e quando il prezzo si muove a proprio favore, l'ordine di stop viene automaticamente aggiornato con il prezzo più recente. Al contrario, quando il prezzo si muove a vostro sfavore, l'ordine di stop loss si attiva e la posizione viene chiusa alla distanza impostata per la perdita. Si noti che qualsiasi ordine può essere cortocircuitato a causa della volatilità del mercato, nel qual caso il sistema non sarà in grado di eseguire l'ordine al prezzo predefinito, ma chiuderà la posizione per voi al prezzo successivo più favorevole rispetto al prezzo target.",
			row3_p2: "Il prezzo attuale di EUR/USD è 1,13816/1,13837 (vendere/acquistare). Avete stabilito un ordine di acquisto di 1 lotto a 1,13837 con un trailing stop loss di 100 pip (0,00100).",
			row3_p3: "Quando il prezzo del prodotto è a 1,13816, il vostro Stop Loss è a 1,13716. Se il prezzo di vendita del prodotto sale a 1,13845, lo Stop Loss sarà aggiornato della distanza che avete impostato, e lo Stop Loss aggiornato sarà a 1,13745.",
			row3_p4: "Al contrario, quando il prezzo del prodotto scivola da 1,13845 a 1,13745, verrà attivato un trailing stop e la posizione verrà chiusa a 1,13745.",
			tip: "Punto: di solito, la più piccola unità di variazione del prezzo di uno strumento finanziario è chiamata punto. Nella piattaforma HKDFINANC, si riferisce all'ultima cifra o decimale del prezzo dello strumento.",
		},
		sentiment: {
			tit: "Indice emozionale",
			type1: "Completo",
			type2: "Cambio di valuta estera",
			type3: "Merce",
			type4: "Esponenti",
			long: "A lungo termine (finanza)",
			short: "Breve (finanza)",
		},
	},
	layout: {
		aside: {
			nav1: "Rapporti",
			nav2: "La situazione attuale del mercato",
			nav3: "Informazioni",
			nav4: "Università",
			nav5: "Mio",
			newsDialog: {
				title: "Centro Notizie",
				type1: "Notifica del sistema",
				type2: "Bollettino",
				allRead: "Contrassegnare tutti come letti",
			},
			settingDialog: {
				title: "Allestimento",
				nav1: "Convenzionale (armi)",
				nav2: "Mostra preferenze",
				nav3: "Informazioni sul sistema",
				logout: "Disconnettersi",
				setting1: "Multilinguismo",
				setting2: "Rapporti",
				setting2_tip: "Le posizioni aperte aggiungono automaticamente le varietà auto-selezionate",
				setting3: "Rispedire le informazioni",
				setting3_tip: "Raccomandazioni funzionali",
				setting4: "Autenticazione con nome reale",
				setting4_tip: "Autenticazione con nome reale",
				setting5: "Modalità Tema",
				setting5_label1: "Oscuramente",
				setting5_label2: "cColore brillante",
				setting6: "Colori crescenti o decrescenti",
				setting6_label1: "Illuminato. il verde sale e il rosso scende",
				setting6_label2: "Illuminato. il rosso sale, il verde scende",
				setting7: "Impostazioni del grafico",
				setting7_label1: "Edizione standard",
				setting7_label2: "Edizione TradingView",
				setting8: "Dichiarazioni e accordi",
				setting8_label1: "Informativa sulla privacy",
				setting8_label2: "Dichiarazione di divulgazione del prodotto",
				setting8_label3: "Dichiarazione di rischio",
				setting8_label4: "Accordo con il cliente",
				feedback_title: "Raccomandazioni funzionali",
			}
		},
		footer: {
			hot: "Varietà popolari",
			sort: "Assortimento",
			code: "Codifica",
			buy_price: "Prezzo di acquisto",
			sell_price: "Prezzo del venditore",
			chg: "Aumento o diminuzione del prezzo",
		},
		header: {
			place_search: "Ricerca delle varietà commerciali",
			balance: "Saldo disponibile",
			balance_tip: "Saldo disponibile: la quantità di denaro presente nel conto che può essere utilizzata per aprire nuove posizioni.",
			profit_loss: "Cera e calante",
			asset_view: "Panoramica delle attività",
			netValue: "Valore netto",
			netValue_tip: "Patrimonio netto: valore del conto corrente, compresi i guadagni e le perdite su tutte le posizioni",
			marginLevel: "Livello di margine",
			margin: "Margine ",
			margin_tip: "Margine: importo richiesto per aprire e mantenere una posizione in una valuta estera.",
			maintainsMargin: "Margine di manutenzione",
			maintainsMargin_tip: "Margine di mantenimento: l'importo minimo del margine che deve essere mantenuto sul conto mentre si mantengono tutte le posizioni.",

		},
	},
	table: {
		label_name: "Nome (di una persona o di una cosa)",
		label_buyPrice: "Prezzo dell'offerta",
		label_sellPrice: "Tasso di vendita",
		label_variableValue: "Valore del cambiamento",
		trade: "Rapporti",
		label_code: "Codice prodotto",
		label_title: "Nome (di una cosa)",
		label_start: "Significato",
		label_country: "Nazioni",
		label_event: "Evento",
		label_previous: "Valore precedente",
		label_predictive: "Valore previsto",
		label_announced: "Valore pubblicato",
		label_stop_loss_min: "Distanza minima per gli ordini stop loss",
		label_difference: "Diffusione dinamica",
		label_contract_size: "Dimensione del contratto",
		label_max_hand: "Dimensione massima del lotto per una singola transazione",
		label_min_hand: "Dimensione minima del lotto",
		label_trade_week: "Calendario settimanale di trading",
		label_week: "1 Settimana",
		label_month: "1 Gennaio",
		label_quarter: "1 trimestre",
		label_dailyTrend: "Tendenza del singolo giorno",
		label_tradeId: "ID transazione",
		label_breed: "Assortimento",
		label_tradeType: "Tipo di transazione",
		label_currency: "Valuta",
		label_amount: "Somma di denaro",
		label_balance: "Bilanci",
		label_type: "Tipologia",
		label_time: "Tempi",
		label_orderNumber: "Numero d'ordine",
		label_pointer_number: "Numero ordinale",
		label_price_money: "Prezzo di apertura",
		label_stop_win_price: "Prendere profitto",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Orario di apertura",
		label_profit_loss: "Cera e calante",
		label_sell_buy: "Prezzo di richiesta/acquisto",
		label_chg: "Aumento o diminuzione del prezzo",
		label_sell_price: "Prezzo del venditore",
		label_buy_price: "Prezzo di acquisto",
		label_condition: "Prerequisito",
	},
	form: {
		label_feedback: "Problemi e raccomandazioni",
		place_feedback: "Inserite la vostra domanda o il vostro suggerimento",
		label_img: "Fotografia",
		label_img_tip: "Facoltativamente, fornire una schermata del problema",
		feedback_tip: "Se avete una domanda urgente, contattate",
		online: "Servizio clienti online",
		label_name: "Nome e cognome",
		place_name: "Inserisci il tuo nome",
		label_email: "Indirizzo e-mail",
		place_email: "Indirizzo e-mail",
		message_email: "Inserisci il tuo indirizzo e-mail",
		label_nationality: "Cittadinanza",
		place_nationality: "Inserisci la tua nazionalità",
		place_questionType: "Tipo di problema",
		message_questionType: "Selezionare il tipo",
		questionType0: "Piattaforma di trading",
		questionType1: "Prodotti e tariffe",
		questionType2: "Abbonati alle analisi di mercato giornaliere",
		questionType3: "Servizio clienti",
		questionType4: "Il resto",
		place_question: "La vostra domanda",
		message_question: "Inserire una domanda",
		submit_question: "Presentazione delle domande",
		label_phone: "Numero di telefono cellulare",
		place_phone: "Inserire il numero di cellulare",
		message_phone: "Inserire il numero di cellulare",
		label_password: "Crittografico",
		place_password: "Inserire la password",
		message_password: "Inserire la password",
		label_confirmPassword: "Conferma la password",
		place_confirmPassword: "Inserire nuovamente la password",
		message_confirmPassword: "Inserire nuovamente la password",
		label_captcha: "CAPTCHA, un tipo di test di sfida-risposta (informatica)",
		place_captcha: "Inserire il codice di verifica",
		message_captcha: "Inserire il codice di verifica",
		get_captcha: "Ottenere CAPTCHA",
		label_inviteId: "Codice invito",
		place_inviteId: "Codice invito (facoltativo)",
		to: "Fino a quando",
		start_time: "Ora di inizio",
		end_time: "Ora di fine",
		label_cardNumber: "Numero del certificato",
		place_cardNumber: "Inserire il numero di identificazione",
		message_cardNumber: "Inserire il numero di identificazione",
		label_cardMain: "Parte anteriore del documento",
		message_cardMain: "Inserire il fronte del documento",
		label_cardBack: "Il retro di un documento",
		message_cardBack: "Inserire il retro del documento",
		confirm_modify: "Conferma delle modifiche",
		label_realName: "Nome e cognome",
		place_realName: "Inserisci il tuo nome",
		message_realName: "Inserire il nome completo",
		label_firstName: "Nome della famiglia",
		place_firstName: "Inserire il proprio cognome",
		message_firstName: "Inserire il cognome",
		label_lastName: "Nome (di una cosa)",
		place_lastName: "Inserire il secondo nome",
		message_lastName: "Inserire un nome",
		label_birthday: "Data di nascita",
		place_birthday: "Inserire la data di nascita",
		message_birthday: "Inserire la data di nascita",
		place_nowPsd: "Inserire la password attuale",
		message_nowPsd: "Inserire la password attuale",
		place_newPsd: "Inserire una nuova password",
		message_newPsd: "Inserire una nuova password",
		place_crmPsd: "Confermare la nuova password",
		message_crmPsd: "Confermare la nuova password",
		label_breed: "Assortimento",
		place_breed: "Inserire una specie",
		label_phase: "(Coll.) bocciare (uno studente)",
		label_buyPrice: "Prezzo di acquisto",
		label_sellPrice: "Prezzo del venditore",
		label_do: "A causa di",
		label_height: "Maggiore di",
		label_low: "Essere inferiore a",
		label_equal: "O uguale a",
		labelWalletName: "Nome del portafoglio",
		messageWalletName: "Inserire il nome del portafoglio",
		placeWalletName: "Inserire il nome del portafoglio",
		labelWalletAddress: "Indirizzo del portafoglio",
		messageWalletAddress: "Inserire l'indirizzo del portafoglio",
		placeWalletAddress: "Inserire l'indirizzo del portafoglio",
		labelAmount: "Somma di denaro",
		messageAmount: "Inserire l'importo",
		placeAmount: "Inserire l'importo",
		placeTimeLimit: "Inserire un limite di tempo",
		messageTimeLimit: "Inserire un limite di tempo",
	},
	pay: {
		title: "Facile accesso ai fondi",
		p1: "Una varietà di canali di pagamento comunemente utilizzati, supporto per depositi e prelievi rapidi",
		p2: "Alcuni metodi di pagamento potrebbero non essere disponibili nel vostro Paese.",
		p3: "Benvenuti a sperimentare i servizi di HKDFINANC",
		btn: "Apri un conto e fai trading ora",
	},
	header: {
		tip1: "Il trading con leva è un prodotto finanziario complesso con un elevato rischio di perdite rapide.",
		btn1: "Piattaforma di trading",
		btn2: "Disconnettersi",
		btn3: "Accedi",
		BigUint64Array: "Commercio ora",
	},
	footer: {
		help: "Desiderare？",
		tip1: "Segui la nostra comunità",
		tip2: "Si prega di notare che HKDFINANC non ha creato un gruppo Telegram ufficiale e qualsiasi gruppo Telegram formato a nome di HKDFINANC è sospettato di essere uno spoofing.",
		tip3: "AVVISO DI RISCHIO: il trading può comportare la perdita di tutti i vostri fondi. Il trading di derivati OTC non è adatto a tutti. Si prega di leggere attentamente i nostri documenti legali prima di utilizzare i nostri servizi e di assicurarsi di aver compreso appieno i rischi connessi prima di fare trading. L'utente non possiede o detiene effettivamente nessuna delle attività sottostanti.",
		tip4: "HKDFINANC non fornisce alcun consiglio, raccomandazione o opinione sull'acquisto, la detenzione o la vendita. Tutti i prodotti offerti sono derivati OTC basati su attività globali. Tutti i servizi forniti da HKDFINANC si basano esclusivamente sull'esecuzione di ordini di compravendita.",
		tip5: "HKDFINANC è un marchio commerciale condiviso da diverse società e gestito attraverso le seguenti società:",
		tip6: "HKDFINANC International Ltd è l'emittente dei prodotti finanziari descritti o disponibili per l'uso su questo sito web. HKDFINANC International Ltd è autorizzata e regolamentata dalla Financial Services Commission (FSC) di Mauritius con il numero di licenza GB20025791, con sede legale al 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius. Street, 1° piano River Court, Port Louis 11328, Mauritius",
		tip7: "HKDFINANC Global Pty Ltd è registrata con ABN 90 149 011 361, Australian Financial Services Licence (AFSL) numero 398528.",
		tip8: "HKDFINANC Holding Ltd è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con licenza SIB numero 1612446.",
		tip9: "Le informazioni contenute in questo sito web non sono destinate ai residenti degli Stati Uniti d'America, del Canada, del Giappone o della Nuova Zelanda, né sono destinate all'uso da parte di chiunque in qualsiasi paese o giurisdizione in cui la pubblicazione o l'uso sarebbero contrari alle leggi o ai regolamenti locali. L'inglese è la lingua principale dei nostri servizi ed è la lingua legalmente vincolante di tutti i nostri documenti contrattuali. Le traduzioni in altre lingue sono solo di riferimento e in caso di discrepanze tra la versione inglese e quella cinese, prevarrà la versione inglese.",
		tip10: "Crittografia di comunicazione sicura SSL. Copyright © HKDFINANC, Tutti i diritti riservati.",
		link1: "Informativa sulla privacy",
		link2: "Dichiarazione di divulgazione del prodotto",
		link3: "Processo di reclamo",
		link4: "Dichiarazione di rischio",
		link5: "Accordo con il cliente",
		toTop: "Appiccicoso (di una discussione in un forum su Internet, ecc.)",
	},
	nav: {
		tit1: "Offerte",
		tit2: "Rapporti",
		tit3: "Apprendimento degli investimenti",
		tit4: "Chi siamo",
		nav1: "Cambio di valuta estera",
		nav2: "Esponenti",
		nav3: "Merce",
		nav4: "Stock (mercato)",
		nav5: "Piattaforma di trading",
		nav6: "Strategia di trading",
		nav7: "Prospettive di trading",
		nav8: "Calendario finanziario",
		nav9: "Notizie in tempo reale",
		nav10: "Preventivi in tempo reale",
		nav11: "Indice emozionale",
		nav12: "Gestione del rischio",
		nav13: "Specifiche del contratto",
		nav14: "Introduzione agli investimenti",
		nav15: "Approfondimento sugli investimenti",
		nav16: "Academy",
		nav17: "Informazioni su HKDFINANC",
		nav18: "Premi e onorificenze",
		nav19: "Centro Media",
		nav20: "Sicurezza finanziaria",
		nav21: "Tasse e oneri",
		nav22: "Affiliates",
		nav23: "Contatto",
		nav24: "Problemi comuni",
		nav25: "Centro di assistenza",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austriaco",
		bahrain: "Bahrain",
		belgium: "Belgio",
		bosnia_and_Herzegovina: "Abbreviazione di Bosnia-Erzegovina",
		brazil: "Brasiliano",
		brunei: "Brunei Darussalam, sultanato indipendente nel Borneo nordoccidentale",
		bulgaria: "Bulgaria",
		cambodia: "Cambogiano",
		canada: "Canadese",
		cameroon: "Camerun",
		chile: "Cile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Repubblica di Croazia (1991-)",
		cyprus: "Cipro",
		czech_Republic: "Repubblica Ceca (dal 1993)",
		denmark: "Danimarca",
		dominican_Republic: "Repubblica Dominicana",
		egypt: "Egitto",
		estonia: "Estonia",
		ethiopia: "Etiopia",
		finland: "Suomi",
		france: "Francese",
		georgia: "Georgia (paese)",
		germany: "Tedesco",
		ghana: "Ghana, Africa occidentale",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Ungheria",
		iceland: "Islandese",
		ireland: "Irlandese",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israele",
		iran: "Iraniano",
		iraq: "Iracheno",
		japan: "Giapponese",
		kazakstan: "Kazakistan",
		kenya: "Kenya",
		korea: "Corea del Sud (Repubblica di Corea)",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonia",
		lithuania: "La repubblica lituana, ex repubblica sovietica del Baltico",
		luxembourg: "Lussemburgo",
		macao_China: "Macao, Cina",
		macedonia: "Macedone",
		malaysia: "Malesia",
		malta: "Maltese",
		mexico: "Messico",
		moldova: "Repubblica di Moldova, ex repubblica sovietica al confine con la Romania",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Marocco",
		myanmar: "Myanmar",
		netherlands: "Paesi Bassi",
		new_Zealand: "Nuova Zelanda",
		nepal: "Nepali",
		nigeria: "Nigeria, Africa occidentale",
		norway: "Norvegia",
		oman: "Omaniti",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peruviano",
		philippines: "Filippine",
		poland: "Polacco",
		portugal: "Portogallo",
		puerto_Rico: "Porto Rico, territorio non incorporato e autogovernato degli Stati Uniti.",
		qatar: "Doha",
		romania: "Romania",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruandese",
		saudi_Arabia: "Arabia Saudita",
		serbia: "Serbia",
		singapore: "Singaporiano",
		slovakia: "Slovacchia",
		slovenia: "Slovenia",
		south_Africa: "Sudafrica",
		spain: "Spagnolo",
		sri_Lanka: "(Ex) Ceylon",
		sweden: "Svezia",
		switzerland: "Svizzera",
		taiwan_China: "Cina-Taiwan",
		tajikistan: "Tagikistan",
		tanzania: "Tanzania",
		thailand: "Thailandia",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Bielorussia",
		united_Arab_Emirates: "EMIRATI ARABI UNITI",
		united_Kingdom: "Regno Unito di Gran Bretagna e Irlanda del Nord",
		united_States: "Stati Uniti d'America",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaigian, ex repubblica sovietica e regione dell'Iran nordoccidentale nel Caucaso",
		bangladesh: "Bangladesh ",
		belarus: "Bielorussia",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Isole Vergini britanniche",
		burkina_Faso: "Burkina Faso, Africa occidentale",
		burundi: "Burundi",
		cape_Verde: "Capo Verde",
		cayman_Islands: "Isole Cayman",
		central_African_Republic: "Repubblica Centrafricana",
		chad: "Ciadiano",
		comoros: "Comore",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Repubblica Democratica del)",
		djibouti: "Gibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Equatoriale",
		eritrea: "Eritrea",
		fiji: "Figi ",
		gabon: "Gabonese",
		gambia: "Gambia",
		greenland: "Groenlandia",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti, il terzo occidentale dell'isola caraibica di Hispaniola",
		isle_of_Man: "Isola di Mann",
		cote_d_Ivoire: "Costa d'Avorio o Costa d'Avorio, in Africa occidentale.",
		jamaica: "Giamaicano",
		jordan: "jordan",
		lebanon: "Ostilità",
		lesotho: "Lesotho, capitale del Sudafrica",
		liberia: "Liberia",
		libya: "Libia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Le Maldive",
		mali: "Mali, Africa occidentale",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambico",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (Stato africano)",
		north_Korea: "Dinastia coreana Joseon o Chosun 1392-1910",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadiscio",
		sudan: "Sultano",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Siria",
		togo: "Togo, Africa occidentale",
		tonga: "Tonga, regno dell'arcipelago del Pacifico meridionale",
		tunisia: "Tunisi, capitale della Tunisia",
		united_States_Virgin_Islands: "Isole Vergini Americane (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vaticano",
		yemen: "Yemenita",
		yugoslavia: "Jugoslavia, 1943-1992",
		zambia: "Seychelles",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};

