export default {
	crypto: {
		title: {
			0: "Buy Crypto",
			1: "Buy Crypto",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Buy",
			2: "Sell",
			3: "Advertisers (Completion rate)",
			4: "Trades",
			5: "Price",
			6: "Available",
			7: "Limit",
			8: "Payment Method",
			9: "Trade",
			10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
		},
		buy: {
			0: "Buy Crypto",
			1: "Sell Crypto",
			2: "History",
			3: "Support",
			4: "Pay",
			5: "Receive",
			6: "Select payment method",
			7: "Est. arrival time",
			8: "FEE",
			9: "Order Details",
			10: "Payment method",
			11: "Learn more",
			12: "Price",
			13: "Total (fee included)",
			14: "Disclaimer",
			15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
			16: "I have read and agree to the Terms of Use",
			17: "You will get",
			18: "Submit",
			19: "Please enter the amount",
			20: "Insufficient account balance",
			21: "Available",
			22: "Spot Account Availiable",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		}
	},
	common: {
		confirm: "Define",
		cancel: "Törlések",
		tip: "Felhívni a figyelmet valamire",
		place_select: "Kérjük, válassza ki",
		submit: "Benyújtani (jelentést stb.)",
		more: "További",
		deposit: "Letét",
		real_trade: "Real Deal",
		paper_trade: "Demo kereskedés",
		build_paperTrade: "Hozzon létre egy demószámlát",
		start_trade: "Kezdje el a kereskedést most",
		app_name: "HKDFINANC",
		success: "Sikerek！",
		dataSources_name: "Trading Central",
		reset: "Revízió",
		iknow: "Tudomást szerezni",
		noData: "Nincs adat",
		modify: "Módosítások",
		pingcang: "Pozíciót lezárni",
		all: "Teljes",
		placeEnter: "Kérjük, adja meg",
		countryCode: "Ország/terület kódja",
	},
	currency: {
		btcusdt: "Bitcoin (virtuális valuta)",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (virtuális valuta)",
		xrpusdt: "Ripple (valuta)",
		adausdt: "Ada pénznem (a pénznem tört részeiben használatos)",
		eosusdt: "Pomelo érme",
		dotusdt: "Boca Raton (korábbi nevén Boca Raton), orosz valuta",
		trxusdt: "Wave farmland érme (számítástechnika)",
		xmrusdt: "Monroe érme (pl. amerikai dollár)",
		dogeusdt: "Kutyaérme",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Ausztrál dollár USA dollár",
		fx_sgbpusd: "Font-dollár",
		fx_seurusd: "Eurodollár",
		fx_snzdusd: "Új-zélandi dollár (NZD) Amerikai dollár",
		fx_susdcad: "USA-dollár-Kanadai dollár",
		fx_susdchf: "USA-dollár Svájci frank",
		fx_susdjpy: "USA-dollár-yen",
		fx_saudcad: "Ausztrál dollár Kanadai dollár",
		fx_saudchf: "Ausztrál dollár és svájci frank",
		fx_saudjpy: "Ausztrál dollár Japán jen",
		fx_saudnzd: "Ausztrál dollár Új-zélandi dollár (NZD)",
		fx_scadchf: "Kanadai dollár Svájci frank",
		fx_scadjpy: "Kanadai dollár Japán jen",
		fx_schfjpy: "Svájci frank jen",
		fx_seuraud: "Euro Ausztrál dollár",
		fx_seurcad: "Euro Kanadai dollár",
		fx_seurgbp: "Euro Font sterling",
		fx_seurjpy: "Euró Jen",
		fx_seurnzd: "Euro Új-zélandi dollár (NZD)",
		fx_sgbpaud: "Brit font Ausztrál dollár",
		fx_sgbpchf: "Font sterling svájci frank",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Font Új-zélandi dollár (NZD)",
		fx_scadnzd: "Kanadai dollár Új-zélandi dollár",
		hf_CL: "WTI New York nyersolaj",
		hf_OIL: "Brent nyersolaj",
		hf_GC: "New York-i arany",
		hf_SI: "New York Silver",
		hf_HG: "Copper (kölcsönszó)",
		hf_NG: "Egyesült Államok Földgáz",
		hf_CAD: "Copper (kölcsönszó)",
		hf_AHD: "Londoni alumínium",
		HX_AAPL: "Gránátalma",
		HX_BABA: "Ali Baba, az Ezeregyéjszaka szereplője",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (kiskereskedő)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), szerb feltaláló és mérnök.",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Németország",
		HX_IBEX: "IBEX35, Spanyolország",
		HX_SPX: "S&P 500 index",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "Új-Zéland 50",
		HX_PSI20: "Portugália PSI20",
		hf_ZSD: "Londoni cink",
		hf_NID: "London Nickel",
		hf_PBD: "Londoni vezetés",
		hf_SND: "Londoni konzervdoboz",
		hf_XAU: "Londoni arany (spot arany)",
		hf_XAG: "Londoni ezüst (Spot ezüst)",
		hf_XPT: "Platina határidős ügyletek",
		hf_XPD: "Palládium határidős ügyletek",
		hf_CT: "US Cotton",
		hf_SM: "Szójabab por",
		hf_BO: "Amerikai szójaolaj",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karacsi, Pakisztán",
		HX_FCHI: "CAC40, Franciaország",
		HX_SX5E: "Európai Snooker 50",
		HX_ICEXI: "ICEX, Izland",
		HX_ASE: "ASE Athén, Görögország",
		HX_OMXC20: "OMX Koppenhága 20",
		HX_OSEBX: "OSEBX, Norvégia",
		HX_OMXSPI: "OMXSPI, Svédország",
		HX_AMD: "Chaowei félvezető",
		HX_DIS: "Disney (név)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (márka)",
		HX_NVDA: "NVIDIA, számítógépes grafikus kártyákat gyártó vállalat",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Seagate Technology",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	addnew: {
		paypass: "Kereskedelmi jelszó",
		loginpassTit: "Jelszó módosítása",
		paypassTit: "Módosítsa ATM jelszavát",
		paypassTit2: "A közösségi jelszó kezdetben a bejelentkezési jelszó.",
		buyUp: "Szárnyaló",
		buyDown: "Vásárolni és eladni",
		addressName1: "China Gold Financial (International) Holdings Limited",
		addressValue1: "1101-Es szoba, 11. emelet, Koon Kwan Mansion (korábban Citibank Tower), 3 Garden Road, Central, Hongkong, Hongkong",
		addressName2: "Hongkong Zhong Cai Financial Investment Company Limited",
		addressValue2: "Zhongcai Centre, 131-133 Queen's Road Central, Central, Hongkong, Hongkong",
		addressName3: "Po Sun Financial Holdings Limited",
		addressValue3: "1908-as szoba, 19. emelet, 89 Queensway, Admiralty, Hong Kong Lippo Centre Tower 2",
		loan: "Hitelfelvétel (pl. egy banktól)",
		repaid: "Visszafizetve",
        unpaid: "Vissza nem fizetés",
		loanMore: "Kölcsönt akarok.",
		immediateRepayment: "Azonnali visszafizetés",
		accountType1: "Érme számla",
		accountType2: "Szerződéses számla",
		accountType3: "Opciós számla",
		from: "Át (egy résen)",
        to: "Amíg",
		get: "Kap",
		labelBreed: "Pénznem típusa",
		placeBreed: "Kérjük, válassza ki a pénznemet",
		labelTransAccount: "Átutalási számla",
		labelAmount: "Átutalt összeg",
		placeAmount: "Kérjük, adja meg az átutalás összegét",
		transferTit:"Számlaátutalások",
		convertTit:"Coin flash exchange",
		balanceTit:"Számlaegyenleg",
		available: "Rendelkezésre álló hitel",
        pending: "Lock (a számítógépes rendszerhez, eszközhöz vagy fájlokhoz való hozzáférés megtagadása)",
        equivalent: "Az összeg",
		coinTrade: "Érmék kereskedelme",
		secondContract: "Második szerződés (a sportban)",
		number: "Mennyiségek",
		labelNumber: "Mennyiségek",
		placeNumber: "Kérjük, adja meg a váltságdíjak számát",
		time: "Times",
		loadAmount: "Várható kölcsönösszeg",
		repaymentCycle: "Hitel-visszafizetési ciklus",
		dailyRate: "Napi kamatláb",
		repaymentMethod: "Visszafizetési mód",
		loanTip: "Hitelhitelek (kérjük, győződjön meg róla, hogy a képek jól láthatóak)",
		loanTip1: "Tulajdoni tanúsítvány feltöltése",
        loanTip2: "Jövedelemigazolás (munkaviszony)",
        loanTip3: "Bankszámlakivonat adatai",
        loanTip4: "Töltse fel személyi igazolványa előlapi fényképét",
        interest: "Érdeklődés (kívánság tudni valamiről)",
		repaymentMethod1: "Egy részletben esedékes visszafizetés",
	},
	params: {
		product_foreign: "Deviza (valuta) átváltás",
		product_shop: "Áru",
		product_number: "Exponents",
		product_stock: "Készlet (piac)",
		product_coin: "Titkosított",
		billTypes: [
			{v: 101, name: 'Feltöltés (pénz feltöltése kártyára)'},
			{v: 102, name: 'Letétbe helyezés (pl. bankszámlán)'},
			{v: 103, name: 'Befagyasztás (hitel, bér, ár stb.)'},
			{v: 104, name: 'Felolvasztás'},
			{v: 105, name: 'Letétbe helyezés (pl. bankszámlán)'},
			{v: 106, name: 'Letétbe helyezés (pl. bankszámlán)'},
			{v: 201, name: 'Visszavonás befagyasztása'},
			{v: 202, name: 'Pénzt kivenni'},
			{v: 203, name: 'Sikeres visszavonás'},
			{v: 204, name: 'Elmulasztja a pénzeszközök felvételét'},
			{v: 205, name: 'Visszavonási díj'},
			{v: 206, name: 'Áthelyezés'},
			{v: 207, name: 'Váltás'},
			{v: 208, name: 'Érmeátadás'},
			{v: 209, name: 'Érmeátadás'},
			{v: 301, name: 'Szerződéskezelési díj'},
			{v: 302, name: 'Szerződéses jövedelem'},
			{v: 303, name: 'Szerződés elvesztése'},
			{v: 304, name: 'Szerződéses árrés'},
			{v: 305, name: 'Szerződéses árrés megtérülése'},
			{v: 311, name: 'Opciók vásárlása'},
			{v: 312, name: 'Opciós nyereség'},
			{v: 313, name: 'Visszatérési opció'},
			{v: 314, name: 'Opciós kezelési díj'},
			{v: 315, name: 'Érme vásárlás befagyasztása'},
			{v: 316, name: 'Érmevásárlási levonás'},
			{v: 317, name: 'Érmevásárlás visszatérítés'},
			{v: 318, name: 'Érmés befizetés a számlára'},
			{v: 319, name: 'Érmés befizetés a számlára'},
			{v: 320, name: 'Érmeeladás befagyasztása'},
			{v: 321, name: 'Érmeeladási levonás'},
			{v: 322, name: 'Érme eladása visszatérítés'},
			{v: 323, name: 'Érme eladása számlára'},
			{v: 324, name: 'Érme eladása számlára'},
			{v: 325, name: 'Érme kezelési díj'},
			{v: 401, name: 'Bányászok csatlakoznak'},
			{v: 402, name: 'Bányász visszatérés'},
			{v: 403, name: 'Bányagépekből származó bevétel'},
			{v: 404, name: 'Bányászati gép kilépése'},
			{v: 405, name: 'Bányászati kilépési díj'},
			{v: 411, name: 'Hitelfelvételből származó jövedelem'},
			{v: 412, name: 'Kölcsönzési díj'},
			{v: 413, name: 'Sikeres visszafizetés'},
        ],
	},
	message: {
		logout: "Kijelentkezés",
		register_success: "Sikeres regisztráció",
		place_inner_personal: "Kérjük, először adja meg személyes adatait",
		submit_success: "Sikeresen benyújtva！",
		copy_success: "Siker másolása！",
		copy_error: "Reprodukciós kudarc！",
		modify_success: "Sikeresen módosítva",
		no_balance: "Elégtelen egyenleg a kaució fedezésére",
		palce_password: "Kérjük, adja meg jelszavát",
		trade_success: "Sikeres tranzakció",
		placeEnterAmount: "Kérjük, adja meg az összeget",
		tipTit1: "Biztos, hogy ki akarja fizetni ezt a megrendelést?",
	},
	web: {
		account: {
			menu1: "Számlainformációk",
			menu2: "Az eszközeim",
			menu3: "Számlajelentések",
			menu4: "Számla biztonsága",
			menu5: "Értesítések",
			menu6: "Tevékenységi központ",
			menu7: "A pénztárcám.",
			menu8: "Pénzt felvenni",
			walletAdd: "Új pénztárca hozzáadása",
			report: {
				history_tit: "Tranzakciótörténeti jelentés",
				stream_tit: "Pénzforgalomról szóló jelentés",
				desc: "Kérjük, válassza ki a megfelelő dátumtartományt a számlajelentés exportálásához. (Az elmúlt 1 év számlainformációihoz férhet hozzá)",
				select_date: "Dátumtartomány kiválasztása",
				week: "Az elmúlt héten",
				month: "Körülbelül egy hónap",
				threeMonth: "Az elmúlt három hónap",
				sixMonth: "Az elmúlt hat hónap",
				time: "Times",
				view_report: "Jelentés megjelenítése",
				email_send: "E-mailben küldött",
				dialog_tit: "Számlajelentések",
				dialog_p1: "A HKDFINANC Holding Ltd. a Kajmán-szigeteken van bejegyezve (SIB szám: 1612446), és az Ön tranzakcióit kereskedelmi szervezetként kezeli.",
				tit2: "Mitade kereskedési előzmények jelentése",
				tit4: "Mitade pénzforgalmi jelentés",
				tit3: "Számlainformációk",
				label1: "Fiók neve",
				label2: "Számlaazonosító",
				label3: "Számla pénzneme",
				label4: "Jelentési idő",
				label5: "Jelentési időszak",
				label6: "Pénzforgalom",
				empty: "Nincsenek lezárt kereskedései",
				email_des: "A jelentést elküldjük az Ön e-mail címére",
				tab1: "Jelentéstétel",
				tab2: "Napi kimutatás",
				tab3: "Havi kimutatás",
			},
			account: {
				tit1: "Üdvözöljük a HKDFINANC-nál",
				tit2: "Teljes személyazonossági ellenőrzés a valódi betétekkel való kereskedéshez",
				p1: "Élő kereskedési számla megnyitása",
				p2: "Hitelesítés",
				btn1: "Hitelesítés",
				tit3: "Személyes információk",
				label_name: "Név és vezetéknév",
				label_email: "E-mail cím",
				label_address: "Lakcím",
				tit4: "Számlainformációk",
				label_account: "Kereskedelmi számla (valós tranzakció)",
				label_currency: "Alapvaluta (valós tranzakciók)",
				label_company: "Folyószámla Társaság",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd A HKDFINANC vállalatcsoport a Kajmán-szigeteki Monetáris Hatóság (CIMA) által engedélyezett és engedélyezett.",
				tit5: "Élő kereskedési számla megnyitása",
				tit6: "A tájékoztatás javítása",
				tit7: "Töltse ki az alapvető adatokat",
				tit8: "Kockázatértékelés",
				tit9: "A kereskedési kockázattűrő képesség felmérése",
				tit10: "Hitelesítés",
				tit11: "A személyazonosság ellenőrzésének elvégzése a szabályozási követelményeknek megfelelően",
				p3: "Kérjük, válassza ki az alábbi igazoló dokumentumok bármelyikét ellenőrzés céljából",
				type1: "Személyi igazolványok",
				type2: "Jogosítvány",
				type3: "Útlevél",
				p4: "Megerősítem, hogy minden megadott információ és dokumentum teljes, igaz és pontos, és vállalom, hogy frissített információkat a HKDFINANC 30 napon belül bármilyen frissítés esetén.",
				next: "A következő lépés",
				btn2: "Akkreditáció átment",
				modify_personal_tit: "Személyes adatok módosítása",
			},
			activity: {
				tit: "Tevékenységi központ",
				desc: "Részt vehet tevékenységekben, hogy jutalmakat kapjon a befektetési jövedelmének növelése érdekében.",
			},
			capital: {
				real_trade: "Real Deal",
				label_nowAccount: "Folyószámla",
				label_tradeAccount: "Kereskedelmi számlaszám",
				label_currency: "Alapvaluta",
				deposit_withdraw_record: "Befizetési és kivételi nyilvántartások",
				capital_flow_details: "A pénzeszközök áramlásának részletei",
			},
			notification: {
				tit: "Értesítési beállítások",
				tit1: "Nyitott értesítési csatornák",
				email: "E-mail cím",
				message: "Szöveges üzenetküldés",
				push: "Push",
				verify: "Érvényesíteni (egy elméletet)",
				verified: "Validált",
				p1: "Kérjük, nyissa meg/igazolja az alábbi értesítési csatornákat, hogy értesüljön a fontos hírekről!",
				type1: "Piactér",
				tit2: "Értesítés a marketingtevékenységekről",
				desc2: "A platform különleges ajánlati információi, üzemeltetési tevékenységek és egyéb hírek",
				tit3: "Használati utasítás",
				desc3: "Gazdag és speciális tudásanyag ",
			},
			security: {
				tit: "Kriptográfiai",
				isSet: "Konfigurált",
				set: "Menj előre és állítsd be",
				verify: "Érvényesíteni (egy elméletet)",
				verified: "Validált",
				tit1: "Biztonsági tanúsítvány",
				email: "E-mail cím",
				phone: "Mobiltelefonszám",
				phone_tit: "Mobiltelefonszám Kötelező",
				password_tit: "Jelszó módosítása",
			},
		},
		market: {
			hot: "Divatban",
			prev: "Előző oldal",
			next: "Következő oldal",
			line: "Időmegosztás",
			main_subplot: "Fő- és mellékgrafikus mutatók",
			main: "Fő chart indikátor",
			subplot: "Chart indikátor",
			go_trade: "Ugrás a tranzakciós oldalra",
		},
		news: {
			tip: "Jogi nyilatkozat: A fenti információk csak általános tájékoztató jellegűek, és nem használhatók fel kereskedési döntések alapjául.",
		},
		trade: {
			state1: "Gazdaságok",
			state2: "Függőben lévő megbízás",
			state3: "Történetek",
			state: "A dolgok állása",
			state4: "Zárt helyzet",
			state5: "Visszavont",
			type: "Tipológia",
			type1: "Teljes",
			type2: "Reach (ár egy árverésen)",
			type3: "Buy-in",
			left_label1: "Önkiszolgáló",
			left_label2: "Nemrég megtekintett",
			left_type1: "Kompakt oszlopos modell",
			left_type2: "Laza oszlopos mintázat",
			left_type3: "Diagram megjelenítése",
			all: "Teljes",
			right_bp: "A tegnapi termés",
			right_sp: "Napjainkban",
			right_height: "Legfelsőbb",
			right_low: "Legalacsonyabb",
			remind_tit: "Új figyelmeztetések",
			remindList: "Emlékeztető lista",
			remind_btn: "Új figyelmeztetések",
			right_tab1: "Ügyletek",
			right_tab2: "Részletek",
			right_sell: "Reach (ár egy árverésen)",
			right_buy: "Buy-in",
			right_sell1: "Reach (ár egy árverésen)",
			right_buy1: "Buy-in",
			right_type: "Tipológia",
			right_type1: "Piaci árlista",
			right_type2: "Függőben lévő megbízás",
			right_priceMoney: "Függő megbízás ára",
			right_pointerNumber: "Szám (tételek)",
			right_lever: "Feszítővas",
			right_balance: "Rendelkezésre álló egyenleg",
			right_stop_profit: "Profitrealizálás",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Növekvő és csökkenő",
			now_order: "Megrendelés most",
			confirm_order: "Megrendelés megerősítése",
			right_tit1: "Kereskedési stratégia",
			right_rinei: "Néhány napon belül",
			right_short: "Rövid távú",
			right_middle: "Félidős",
			right_time: "Kibocsátási idő",
			right_tit2: "Alternatív stratégia",
			right_tit3: "Műszaki felülvizsgálat",
			right_tit4: "Számok",
			right_tit5: "Kereskedési hangulat",
			right_label_sell: "Eladó (áruk)",
			right_label_buy: "Vásárló",
			right_tip: "Csak tájékoztatásul, nem az osztály álláspontja.",
			right_tip2: "15 Percenként frissül",
			right_tit6: "Árváltozás",
			minute: "Percek",
			today: "Jelenleg",
			right_tit7: "Árváltoztatási tartomány",
			now_price: "Aktuális ár",
			right_low_price: "Legalacsonyabb ár",
			right_height_price: "Legmagasabb ár",
			right_tit8: "Szerződéses információk",
			right_tit9: "AUD/CHF",
			right_label1: "Egyszeri tranzakciók száma",
			right_label2: "Maximális tőkeáttétel",
			right_label3: "Szerződés mérete",
			right_label4: "A pozíciók maximális összlétszáma",
			right_label5: "Lebegő spread",
			right_label6: "Éjszakai töltés",
			hand: "Bizonyos típusú munkákhoz értő személy",
			right_collection_time: "Gyűjtési idő",
			right_tip3: "Díjalap: a teljes tranzakció méretéből",
			right_label7: "Margin arány",
			right_label8: "Karbantartási árrés aránya",
			right_label9: "Nyitvatartási idő",
			right_label10: "Aktuális kereskedési ülésszak",
			right_label11: "Következő kereskedési ülésszak",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Platform tranzakció",
		banner_p3: "Forex, árucikkek, indexek, részvények",
		banner_p4: "És népszerűbb pénzügyi termékek",
		banner_p5: "Felfedezheti a világ legforróbb pénzügyi piacait. ",
		hot: "Divatban",
		tit1: "Barátságosabb tranzakciók",
		p1: "Egyszerű és intuitív kezelőfelület a könnyű kezelhetőség érdekében",
		p2: "Webes, mobilalkalmazás és asztali támogatás, kereskedés az Ön keze ügyében",
		p3: "Kockázatkezelési eszközök, mint például stop loss/trailing stop",
		p4: "Több technikai grafikon és pénzügyi naptár, valós idejű hírek",
		p5: "Valós idejű e-mail, SMS és push értesítések",
		p6: "Folyamatos fejlesztés a jobb kereskedési élmény érdekében",
		btn1: "Fedezze fel platformjainkat",
		row3_tit: "Szabadon kereskedhet bármikor, bárhol",
		row3_tip: "Webes, mobilalkalmazás és asztali támogatás, kereskedés az Ön keze ügyében",
		row3_tit2: "Kód beolvasása a letöltéshez",
		row3_tit3: "Asztali",
		row3_tit4: "Mobil",
		regular_tit: "Megbízható nemzetközi platform",
		regular_tip: "Elkötelezettek vagyunk amellett, hogy biztonságos és felelős kereskedelmi környezetet biztosítsunk ügyfeleink számára.。",
		regular_tit1: "Hatósági szervezetek által szabályozott",
		regular_p1: "Az iparági hatóságok által szabályozott és engedélyezett, és az ügyfelek világszerte megbíznak benne.",
		regular_tit2: "A pénzügyi biztonság védelme",
		regular_p2: "A lakossági ügyfelek betéteit a szabályozói követelményekkel összhangban szükség szerint elkülönítik a letéti számlákon.",
		regular_tit3: "Negatív egyenleg védelme",
		regular_p3: "Biztosítani számla negatív egyenleg védelem, negatív egyenleg időben, hogy törölje a nulla, úgy, hogy a veszteség nem haladja meg a bejegyzés összegét, a nyugalom tranzakciókat",
		regular_tit4: "7*24 órás ügyfélszolgálat",
		regular_p4: "Őszinte és professzionális ügyfélszolgálati csapat, 24 órás online támogatás, szívesen megoldja bármilyen problémáját!",
		service_tit: "További szolgáltatások az Ön számára",
		service_tit1: "Kereskedési stratégia",
		service_p1: "Valós idejű kereskedési stratégiák, amelyek segítenek megérteni a legújabb piaci trendeket, jobban megérteni a kereskedés időzítését.",
		service_tit2: "Kereskedelmi ismeretek",
		service_p2: "Tanuljon meg ingyenesen kereskedni a HKDFINANC segítségével, és fejlessze kereskedési készségeit.",
		service_tit3: "Kockázatkezelés",
		service_p3: "Ismerje meg a HKDFINANC által kínált ingyenes kockázatkezelési eszközöket, hogy jobban megvédje eszközeit.",
		step_tit: "Egyszerű és kényelmes számlanyitás",
		step_tip: "Három egyszerű lépés a számlanyitáshoz percek alatt",
		step_tit1: "Beiratkozás",
		step_p1: "Töltse ki az információkat és küldje el jelentkezését",
		step_tit2: "Letét",
		step_p2: "Gyors pénzeszközök befizetése különböző módszerekkel",
		step_tit3: "A kereskedés megkezdése",
		step_p3: "Fedezze fel a kereskedési lehetőségeket és adjon megbízásokat gyorsan",
		award_tit: "Eredményeink",
		award_p1: "Mindig is a kiválóságra törekedtünk és törekszünk a kiválóságra, és elkötelezettek vagyunk amellett, hogy ügyfeleinknek minőségi tranzakciós szolgáltatásokat nyújtsunk.",
		award_p2: "A HKDFINANC márka megtiszteltetésnek érzi, hogy megkapta ezt a rangos díjat az iparág egyik legrangosabb intézményétől, amely elismeri a csapat folyamatos erőfeszítéseit és elkötelezettségét ügyfeleink iránt.",
		row7_tit: "Valós idejű globális pénzügyi információk",
		row7_tit1: "Valós idejű hírek",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Kattintson a letöltéshez",
		p1: "Kereskedés bármikor, bárhol",
		p2: "Gyorsabban és okosabban kereskedjen",
		p3: "0 Jutalékos kereskedelem",
		p4: "A világ legforróbb befektetése",
		renderDom1: "Deviza (valuta) átváltás",
		renderDom2: "Amerikai részvénypiac",
		renderDom3: "Platina",
		renderDom4: "Nyersolaj",
		renderDom5: "Exponents",
		renderDom6: "Kriptovaluta",
		login: "Bejelentkezés",
		elail: "Postaláda",
		phone: "Mobiltelefonszám",
		no_account: "Nincs számla？",
		go_register: "Menjen a regisztrációhoz",
		register: "Beiratkozás",
		now_register: "Regisztráljon most",
		have_account: "Már van fiókja？",
		go_login: "Menjen a bejelentkezéshez",
	},
	about: {
		serviceFee: "Szolgáltatási díj",
		introduction: "Platform bevezetése",
		trade_platform: "Kereskedési platform",
		complaint_process: "Panaszolási eljárás",
		page: "Weboldal",
		place_search: "Keresse meg, amit tudni szeretne",
		no_answer: "Nem találja a kívánt választ?",
		contact_us: "Kapcsolatfelvétel",
		awards: {
			h1: "Díjak és kitüntetések",
			p1: "A kiválóságra való folyamatos törekvésünkben és az ügyfeleinknek nyújtott minőségi tranzakciós szolgáltatások iránti elkötelezettségünkben a HKDFINANC márka megtiszteltetésnek veszi, hogy az iparág egyik tekintélyes szervezete díjat kapott, elismerve a csapat folyamatos erőfeszítéseit és az ügyfeleink iránti elkötelezettségét.",
			p2: "，Vagy tapasztalja meg díjnyertes online kereskedési platformunkat azonnal!",
			name1: "Latin-Amerika legjobb brókercége",
			name2: "Legjobb CFD bróker",
			name3: "Legjobb Fintech Bróker",
			name4: "Top 10 legbefolyásosabb vállalkozás 2021-ben",
			name5: "Legátláthatóbb bróker",
			name6: "Ultimate Fintech Awards",
		},
		charges: {
			h1: "Díjak és illetékek",
			p1: "Az ügyfélélmény javításának szerves részeként a HKDFINANC költséghatékonyabb kereskedési szolgáltatást kíván nyújtani ügyfeleinek. Arra törekszünk, hogy díjainkat és költségeinket illetően nyíltak és átláthatóak legyünk, rejtett díjak nélkül. Az ügyfelek számára a fő tranzakciós költségeket az ajánlati/bekérési különbözet és az egynapos pozíció kamatai jelentik.",
			h2: "Először is, élvezheti a következő előnyöket, amikor a HKDFINANC-nál kereskedik",
			li1: "Azonnali termék árajánlat",
			li2: "Szakmai és technikai mutatók",
			li3: "Exkluzív piaci elemzés",
			free: "Freeware",
			view: "Nézz fel",
			h3: "Vételi és eladási árfolyamrés",
			p2: "A HKDFINANC a szolgáltatásaiért kompenzációként vételi és eladási árfolyamrést számít fel. Ez a díj a termék árajánlatában megjelenik, és Ön ténylegesen megfizeti a különbözetet, amikor pozíciót nyit. Mivel a spread díj nem rögzített, javasoljuk, hogy látogasson el az adott termék árajánlat oldalára, vagy jelentkezzen be a",
			h4: "Betéti és pénzfelvételi díjak",
			p3: "Annak érdekében, hogy ügyfeleink számára minimalizáljuk a költségeket, a legtöbb esetben nem számítunk fel díjat a pénzeszközökhöz való hozzáférésért. Egy harmadik fél cég vagy bank azonban díjat számíthat fel Önnek a befizetés vagy a pénzfelvétel elvégzéséért, például átutalási banki díjat.",
			p4: "A díjakkal kapcsolatos további információkért forduljon ügyfélszolgálati munkatársunkhoz.。",
			p5: "Pl. nemzetközi hitelkártya-tranzakciók, bankszámlára történő be-/kiutalások vagy nem támogatott pénznemben történő tranzakciók (devizakonverzió) stb. révén.",
			h5: "Egy éjszakai pozíció költségei",
			p6: "Ha Ön még mindig tart egy pozíciót 22:00 GMT (22:00 GMT), akkor Önnek egy éjszakai díjat kell felszámítania/levonnia. Az egyes termékekre vonatkozó egynapos díjakkal kapcsolatos kérdéseket az adott termékre vonatkozó ajánlati oldalon vagy a következő weboldalra történő bejelentkezéssel lehet feltenni",
			h6: "Egyéb költségek",
			p7: "Nincs egyéb díj. Díjszabási politikánk teljesen átlátható, és minden olyan elemet, amely díjköteles, előre bejelentünk és közlünk, így nem kell aggódnia a rejtett díjak miatt."
		},
		contact: {
			h1: "Kapcsolatfelvétel",
			address: "Cím",
			custom_email: "Ügyfélkapcsolati e-mail",
			business_email: "Üzleti együttműködés Email",
			h2: "Írjon nekünk.",
			p1: "Ha bármilyen kérdése van szolgáltatásainkkal kapcsolatban, kérjük, töltse ki az alábbi űrlapot, és ügyfélszolgálati szakembereink egyike a lehető leghamarabb válaszol. Alternatívaként, ha feliratkozna a Trading Centralral partnerségben nyújtott napi kereskedési stratégiáinkra, kérjük, válassza a lenti űrlap típusában a 'Feliratkozás a napi piaci elemzésekre' opciót, és adja meg a kívánt nyelvi változatot, és mi regisztrálni fogjuk Önt. Az előfizetési szolgáltatás csak a meglévő HKDFINANC valós ügyfelek számára érhető el.",
			h3: "Képviseleti eljárások",
			p2: "Minden panasz kivizsgálását és megoldását szakosodott csapatunk végzi, további részletekért kérjük, olvassa el a következőket",
		},
		faq: {
			h1: "Gyakori problémák",
			tit1: "Mi a demó számla haszna？",
			tit2: "Hogyan nyisson élő számlát？",
			tit3: "Hogyan hozzon létre piaci megbízást？",
			tit4: "Miért nem tudok rendelést leadni?？",
			p1: "A demószámlák a kereskedési felület, az adatok és a műveletek tekintetében többnyire megegyeznek a valódi számlákkal. A demószámlák 50 000 $ virtuális pénzt tartalmaznak, és arra szolgálnak, hogy az ügyfelek szimulált műveleteken keresztül megismerkedhessenek a platform jellemzőivel anélkül, hogy pénzeszközeiket kockáztatnák.",
			p2: "Élő számla nyitásához kérjük, kövesse az alábbi lépéseket:",
			p3: "1. Lépjen be a HKDFINANC regisztrációs oldalára, a felszólításoknak megfelelően: regisztráljon postafiókot / mobiltelefonszámot, állítsa be a bejelentkezési jelszót, küldje el a megerősítést, és automatikusan hozzon létre egy [Demo Account].",
			p4: "2. A demószámla oldalon válassza a 'Valódi számlára váltás' lehetőséget, és kövesse az eljárást a 'Valódi számla' megnyitásához.",
			p5: "Mielőtt élő számlát nyitna, javasoljuk, hogy olvassa el és értse meg a kereskedéshez kapcsolódó jogi tájékoztatókat.",
			p6: "Kérjük, vegye figyelembe, hogy csak a személyazonosság ellenőrzése után tud befizetni az élő számlájára.",
			p7: "A 'Kereskedés' (Trade) gombra kattintva megjelenik a platformon kereskedésre elérhető összes termék, a jobb oldali beviteli mezőben megadhatja a termék kódját vagy nevét a kereséshez, kiválaszthat egy terméket és a 'Eladás/Vásárlás' (SELL/BUY) gombra kattintva megjelenik a kereskedési ablak, amelyben láthatja az aktuális árat és a becsült szükséges árrést. Manuálisan beállíthatja a megnyitandó kereskedések számát, beállíthatja a stop-loss és take-profit értékeket a kockázat ellenőrzéséhez, majd a 'SELL/BUY' gombra kattintva befejezheti a pozíció megnyitását. Felhívjuk figyelmét, hogy minden pénzügyi termék árai ingadoznak és a piaccal együtt bármikor frissülnek, és a piac változhatott, mielőtt Ön megnyomta volna a VÁSÁRLÁS/ELADÁS gombot.",
			p8: "Először is ellenőrizze, hogy a hálózati kapcsolat normális-e. Amikor a kereskedési fajok piac átmenetileg zárt idézet vagy zárt okozza a megbízások elhelyezésének képtelenségét. Kérjük, további segítségért forduljon online ügyfélszolgálatunkhoz.",

		},
		fundSecurity: {
			h1: "Pénzügyi biztonság",
			p1: "A HKDFINANC-ot hiteles szabályozó szervek engedélyezték és szabályozzák. A megfelelés minden tevékenységünk és kultúránk középpontjában áll. Az Ön pénzének biztonsága a legfontosabb, és minden ügyfélbetétet elkülönítünk.",
			p2: "A lakossági ügyfélbetéteket a szabályozói előírásoknak megfelelően szükség szerint elkülönítve, letéti számlákon tartják.",
			p3: "A HKDFINANC nem használja az ügyfelek pénzeszközeit a törvények és rendeletek által megengedettektől eltérő kereskedelmi tevékenységekre.",
			p4: "A HKDFINANC nem spekulál semmilyen piacon.",
			p5: "Független könyvvizsgálat harmadik fél könyvvizsgáló cégek által",
		},
		help: {
			h1: "Üdvözöljük a HKDFINANC Súgóközpontban",
		},
		media: {
			h1: "Médiaközpont",
			p1: "A HKDFINANC márkával kapcsolatos információk, sajtóközlemények, médiamegjelenések, bejelentések és egyéb információk megtekintése.",
		},
		mitrade: {
			h1: "A HKDFINANC-ról",
			view_certificate: "(Azonosítási módszerek)",
			p1: "A HKDFINANC a hatóságok által engedélyezett és szabályozott fintech vállalat, amely arra összpontosít, hogy a befektetőknek egyszerű és kényelmes kereskedési élményt nyújtson. Az innovatív HKDFINANC diverzifikált kereskedési platformja többször is elnyerte az olyan díjakat, mint a legjobb mobil kereskedési platform és a leginnovatívabb brókercég.",
			p2: "A HKDFINANC platformon keresztül a nemzetközi pénzügyi piacok szélesebb körébe fektethet be és kereskedhet, amely részvényeket, indexeket, árukat, devizákat és több száz más népszerű fajtát foglal magában. Nem csak a felhasználóbarát mobil és webes kereskedési platformokra korlátozódik, a HKDFINANC versenyképes kereskedési költségeket, gyors kereskedési végrehajtást, kiváló ügyfélszolgálatot és rengeteg információs támogatást is biztosít, segítve Önt az időszerű befektetési, kereskedési lehetőségek megragadásában.",
			p3: "A HKDFINANC-ot szigorúan szabályozza egy ágazati hatóság, több ügynökség. A HKDFINANC International-t a mauritiusi Pénzügyi Szolgáltatások Bizottsága (FSC) engedélyezte és szabályozza a GB20025791 számú engedéllyel. A mauritiusi engedéllyel kapcsolatos részletekért látogasson el az FSC hivatalos weboldalára: https://www. fscmauritius.org",
			p4: "A HKDFINANC Global az Ausztrál Értékpapír- és Befektetési Bizottság (ASIC) által engedélyezett pénzügyi szolgáltatási engedéllyel (AFSL 398528) rendelkezik. Az ausztrál engedélyekkel kapcsolatos további információkért kérjük, látogasson el az ASIC weboldalára: www.asic.gov.au.",
			p5: "A HKDFINANC-ot a Kajmán-szigeteki Monetáris Hatóság (CIMA) engedélyezte és szabályozza a SIB Licence No. 1612446. A kajmán-szigeteki engedélyekkel kapcsolatos további információkért kérjük, látogasson el a CIMA hivatalos weboldalára, a www.cima.ky címre.",
			p6: "A HKDFINANC minden üzleti tevékenységét szigorú felügyelet mellett és az összes szabályozásnak megfelelően végzi.",
			h2: "A HKDFINANC háttere",
			p7: "A HKDFINANC egy vezető csapatból áll, amely gazdag tapasztalattal és ismeretekkel rendelkezik a pénzügyi kereskedés és a fintech iparágak területén. A HKDFINANC filozófiája az, hogy könnyebbé és barátságosabbá tegye a kereskedést",
			p8: "Holisztikus stratégiánk és üzleti modellünk lehetővé teszi számunkra, hogy globális és mélyreható megfigyeléssel és ismeretekkel rendelkezzünk az iparág fejlődéséről és a piaci keresletről, így célzottabban tudjuk elosztani az erőforrásokat, folyamatosan innováljuk a technológiát és optimalizáljuk a hatékonyságot, valamint továbbra is kényelmesebb és barátságosabb kereskedelmi élményt nyújtunk ügyfeleinknek. ",
			h3: "Miért a HKDFINANC",
			why1: "Alacsony küszöbértékű kereskedelmi feltételek",
			why2: "Az ágazati hatóságok által szabályozott",
			why3: "Egyszerű és intuitív kereskedési platform",
			why4: "Magas szintű online támogatás",
			why5: "Versenyképes tranzakciós költségek",
			why6: "Negatív egyenleg védelme",
			h4: "Díjak és kitüntetések",
			p9: "Mindig is a kiválóságra törekedtünk és törekszünk a kiválóságra, és elkötelezettek vagyunk amellett, hogy ügyfeleinknek minőségi tranzakciós szolgáltatásokat nyújtsunk.",
			p10: "A HKDFINANC márka megtiszteltetésnek érzi, hogy megkapta ezt a rangos díjat az iparág egyik legrangosabb intézményétől, amely elismeri a csapat folyamatos erőfeszítéseit és elkötelezettségét ügyfeleink iránt.",
			new_add: "Új felhasználók világszerte",
			ol1: "Legjobb hír- és elemzési szolgáltató",
			ol2: "Legjobb Forex bróker Ázsiában",
			ol3: "Ausztrál Forex ügyfél-elégedettségi díjak",
			ol4: "Legjobb mobil alkalmazás",
			ol5: "Ausztrália leggyorsabban növekvő brókercége",
			h5: "A HKDFINANC működési filozófiája",
			tip_tit1: "Kötelezettségek",
			tip_tit2: "Egyszerű, de szép",
			tip_tit3: "Nyílt (nem titkos)",
			tip_tit4: "Új utakat nyitni",
			tip_p1: "Minden kereskedés kockázattal jár, és a HKDFINANC további lépéseket tett annak érdekében, hogy felelős kereskedési környezetet biztosítson ügyfelei számára. A HKDFINANC-ot az iparági hatóságok, több szervezet szabályozza, és magas szintű biztonsággal és stabilitással működik. Platformunk továbbra is számos felelős kereskedési eszközt kínál, beleértve a kockázatkezelést is.",
			tip_p2: "Az egyszerűség a szépség, a részletesség a kiválóság, a HKDFINANC minden részletében elkötelezett a könnyű használat és a felhasználói élmény mellett. Továbbra is optimalizáljuk platformunkat és szolgáltatásainkat abban a reményben, hogy a kezdőtől a tapasztalt befektetőig mindenki ki tudja majd használni a HKDFINANC platformon keresztül elérhető kereskedési lehetőségeket.",
			tip_p3: "Annak érdekében, hogy ügyfeleink teljes mértékben kihasználják a tisztességes és hatékony kereskedési környezetet, a HKDFINANC szigorú és átlátható önszabályozó rendszert követ, hogy átfogó és átlátható platform termékinformációkat, árképzési környezetet és adatokat biztosítson, minden díj világos és nyílt annak biztosítása érdekében, hogy az egész szolgáltatási folyamat teljesen őszinte és átlátható legyen.",
			tip_p4: "A HKDFINANC szilárdan hisz abban, hogy a technológiai fejlődés, a pénzügyi innováció és a szélesebb körű szolgáltatások a növekedés motorjai, és hogy továbbra is képesek vagyunk értéket teremteni és megosztani. Ennek érdekében ösztönözzük a nyitott gondolkodást, és továbbra is ösztönözzük az innovációt, azzal a céllal, hogy újító és vezető szerepet töltsünk be a pénzügyi technológia területén.",
			row6_tit1: "Küldetésünk",
			row6_tit2: "Víziónk",
			row6_tit3: "Munkalehetőségek",
			row6_p1: "Több kereskedni vágyó ember számára olyan tisztességes, hatékony és átlátható kereskedési környezet biztosítása, amelyet nem korlátoz a tér, az idő és a piac, valamint az iparág vezető szerepe a pénzügyi és technológiai innovációban.",
			row6_p2: "Hiszünk abban, hogy a technológiai fejlődés, a pénzügyi innováció és a diverzifikált szolgáltatások azok az erők, amelyek növekedésünk motorjai, és lehetővé teszik számunkra, hogy továbbra is értéket teremtsünk és osszunk meg a társadalomban.",
			row6_p3: "Ennek érdekében ösztönözzük a nyitottságot és támogatjuk az innovációt, azzal a céllal, hogy a folyamatos növekedés és haladás révén a",
			row6_p4: "A világ vezető devizaváltó vállalata",
			row6_p5: "Egy globális csapat vagyunk, amely 7 országban működik, és mivel a csapatunk gyorsan növekszik, mindig keresünk szakembereket, akik csatlakoznának hozzánk. Csináld azt, amit szeretsz egy multikulturális csapatban!",
			row6_p6: "Kérjük, küldje el nekünk e-mailben naprakész önéletrajzát és azt, hogy miért szeretne velünk dolgozni:",
			row6_p7: "Alternatívaként többet is megtudhat a",
			row6_p8: "Talán",
			row6_p9: "Tekintse meg nyitott állásajánlatainkat a。",
			row6_p10: "A HKDFINANC szigorúan védi az Ön személyes adatainak biztonságát. A HKDFINANC nem kér pénzügyi információkat az álláspályázati folyamat során. Nem kérünk semmilyen pénzügyi vonatkozású, hitelkártya, bankszámla információt vagy bármilyen fizetési formát a toborzásunkhoz.",
			row6_p11: "Ha azt gyanítja, hogy egy pozíció csalárd, kérjük, lépjen kapcsolatba velünk az alábbi elérhetőségen keresztül",
			row6_p12: "Vegye fel a kapcsolatot a HKDFINANC HR osztályával. Ha biztos benne, hogy hamis állásajánlattal becsapták, kérjük, forduljon az érintett szervezet helyi osztályához.",

		}
	},
	product: {
		row2: {
			tit: "Kereskedelmi fajták",
			sell: "Reach (ár egy árverésen)",
			buy: "Buy-in",
			trade: "Ügyletek",
			more_product: "További kereskedelmi termékek",
		},
		row3: {
			tit: "Támogatja a több eszközzel történő tranzakciókat",
			tit1: "Támogatja a több eszközzel történő tranzakciókat",
			p1: "A HKDFINANC webalapú, mobil és asztali kereskedési platformokat kínál. Bárhol és bármikor kereskedhet.",
			download: "Letöltés",
		},
		row4: {
			tit: "Válassza ki a HKDFINANC",
			tit1: "Egyszerű és intuitív platform",
			p1: "Az árajánlatok, a kereskedés, a számlavezetés, az információ, a kockázatkezelés és a hatékony funkciók integrációja a jobb felhasználói élmény elérése érdekében.",
			tit2: "Versenyképes tranzakciós költségek",
			p2: "0 kereskedési jutalék, nagyon versenyképes és átlátható alacsony spreadek, alacsony SWAP-ok, így élvezheti az alacsony költségű kereskedést!",
			tit3: "Alacsony küszöbértékű kereskedelmi feltételek",
			p3: "Az egyes tranzakciók minimális lotmérete mindössze 0,01 lot, és alacsony árréssel is nyithat pozíciót.",
			tit4: "Hatóság által szabályozott",
			p4: "Az iparági hatóságok által engedélyezett és szabályozott, az ügyfelek valamennyi betétjét elkülönített tárolóban tartják az ügyfelek eszközeinek biztonsága érdekében.",
			tit5: "Negatív egyenleg védelme",
			p5: "Az Ön számlája soha nem veszít többet a tőkéjénél semmilyen piaci körülmények között, és a negatív egyenlegek időben elszámolásra kerülnek, hogy növeljék az Ön kockázatkezelési képességeit.",
			tit6: "Magas szintű online támogatás",
			p6: "Gyors válasz online ügyfélszolgálat, jobb szolgáltatást nyújt a szakmai csapat szakadatlan erőfeszítései révén",
		},
		row5: {
			tit: "Kapcsolódó kérdések",
		},
		commodities: {
			title: "Áruforgalom",
			p: "Az olyan nyersanyagok, mint a nemesfémek és az energia, a diverzifikált befektetések fontos részét képezik, mivel védelmet nyújtanak az infláció ellen, valamint lehetőséget nyújtanak a befektetések értéknövekedésére. Közülük az arany, az ezüst és az olaj hatalmas volumenben forgalmazott árucikkek, és nyílt és átlátható áraikat, valamint magas likviditásukat a befektetők széles körben üdvözölték. A nyersanyagárak jelentősen ingadoznak olyan tényezők miatt, mint a kereslet és a kínálat, a gazdasági és politikai helyzet, valamint a valuták, így lehetőséget nyújtanak a kockázat-arányos megtérülésre.",
		},
		forex: {
			title: "Devizakereskedelem",
			p: "A devizakereskedés az egyik pénznemnek egy másikra való átváltása, és a devizapiac a világ legforgalmasabb pénzügyi piaca. A devizapiacon rengeteg résztvevő kereskedik, akik a devizapiacon keresztül fizetéseket teljesítenek, fedezik a devizamozgások kockázatát, vagy nyereséget termelnek. A devizakereskedelem a bankok, intézmények és egyéni kereskedők hálózatán keresztül zajlik világszerte, az árfolyamok a nap 24 órájában, a hét 5 napján ingadoznak, és lehetőséget biztosítanak a kockázat-nyerésre.",
		},
		indices: {
			title: "indices",
			p: "A részvényindex egy statisztikai érték, amely egy adott tőzsdén egy adott típusú jellemző részvényt képvisel, és egy adott típusú összetevő részvény általános árszintjét és mozgását jelzi a piacon. A teljes piac vagy ágazat befektetési lehetőségeit tükröző részvényindexekkel való kereskedés csökkentheti az egyes részvényekbe való befektetés kockázatát. Az amerikai, európai, ázsiai és ausztrál piacokon legnépszerűbb indexek, mint például az Australia 200, az US Tech 100, a Hong Kong 50, a Germany 30 stb. különböző lehetőségeket kínálnak a globális részvénypiacokon.",
		},
		shares: {
			title: "Részvénytranzakció",
			p: "A részvény egy tőzsdén kibocsátott forgalomképes értékpapír. A tőkebevonás érdekében a vállalatok a tőzsdén jegyzett tőzsdéken keresztül, a tanúsítványok segítségével osztják szét a vállalat tulajdonjogát. Csak 2019-ben a világ részvénykereskedelme meghaladta a 60 billió dollárt, ami olyan nagy és likvid, hogy a globális befektetők szemében még sok év után is az egyik legnépszerűbb pénzügyi eszköz marad. A részvényárfolyamokat számos tényező befolyásolja, többek között a makrogazdaság, az iparági kilátások és a vállalatok működése, és rendkívül változékonyak, így a befektetési és kereskedelmi lehetőségek mindig jelen vannak.",
		},
		coins: {
			title: "Kriptovaluta",
			p: "A kriptovaluták kódok segítségével létrehozott digitális valuták. A hagyományos banki és kormányzati rendszereken kívül, önállóan működnek. Ahogy a név is sugallja, a kriptovaluták titkosítást használnak a tranzakciók biztosítására és más egységek létrehozását teszik lehetővé. A Bitcoin az eredeti és messze a leghíresebb kriptovaluta, amelyet Satoshi Nakamoto hozott létre és 2009 januárjában indított el. A mai napig nem tudni, hogy a Satoshi Nakamoto név egy személyre vagy egy embercsoportra utal. A bitcoint tekintik az első decentralizált kriptopénznek. Mint minden kriptovalutát, ezt is egy elosztott nyilvános főkönyvként szolgáló blokklánc tranzakciós adatbázison keresztül irányítják. Napjainkban több mint 1000 kriptovaluta áll rendelkezésre az online kereskedéshez."
		}
	},
	trade: {
		data_sources: "Adatforrások",
		tip: "A fenti információk csak tájékoztató jellegűek. A HKDFINANC nem garantálja az információk pontosságát, időszerűségét vagy teljességét, és nem szabad indokolatlanul támaszkodnia a megadott információkra. Ez az információ nem tartalmazza árjegyzékünket, illetve semmilyen pénzügyi eszközzel való kereskedésre vonatkozó ajánlatot vagy felhívást. A HKDFINANC nem pénzügyi tanácsadó cég, és csak megbízás-végrehajtási szolgáltatásokat nyújt. Az olvasóknak azt tanácsoljuk, hogy saját befektetési tanácsot kérjenek. Kérjük, tekintse meg teljes körű jogi nyilatkozatunkat.",
		tip2: "Frissítve minden pénteken 15:00-kor (GMT)",
		tip3: "A Trading Views a piaci kereskedési hangulat előrejelzésére szolgáló eszköz, amely tükrözi az iparági szakértők rövid- és középtávú nézeteit és trend-előrejelzéseit minden egyes forró termékre vonatkozóan.",
		analysis: {
			tit: "Kereskedési stratégia",
			all: "Teljes",
			day: "Néhány napon belül",
			tit1: "Kereskedési stratégia",
			tit2: "Műszaki felülvizsgálat",
		},
		calendar: {
			tit: "Pénzügyi naptár",
			prev_week: "Múlt héten",
			next_week: "Jövő héten",
			place_date: "Keresés dátuma",
			select: "Opciók (mint a számítógépes szoftverek beállításai)",
			select1: "Nemzetek",
			select2: "Jelentőség",
			start_time: "Indulási idő",
			end_time: "Befejezési idő",
			search: "Keresni valamit",
		},
		contract: {
			tit: "Szerződéses előírások",
			type1: "Teljes",
			type2: "Deviza (valuta) átváltás",
			type3: "Áru",
			type4: "Exponents",
			type5: "Amerikai készlet",
			type6: "Ausztrál részvények",
			tit1: "Jelentősebb devizahiteles",
			tit2: "Kisebb devizaárfolyam",
			tit3: "Áru",
			tit4: "Főbb indexek",
			tit5: "Kisebb index",
			tit6: "Amerikai készlet",
			tit7: "Ausztrál részvények",
			tit8: "Általános szabályok",
			p1: "Kényszerített lapossági arány",
			p2: "Függő rendelés érvényessége",
			p3: "Határtalan",
			p4: "Egy éjszakai díjfizetési idő",
			p5: "Napi elszámolás; Nyár: 05:00, Tél: 06:00",
			p6: "Tájékoztatás a tőkeáttételről, az egynapos kamatlábakról stb.",
			p7: "Lásd: Kereskedési platformok",
			p8: "A fentiek minden kereskedelmi fajtára vonatkoznak",
			tit9: "Heti kereskedelmi naptár",
			p9: "Zárás (a piacon)",
			p10: "A fenti kereskedési órák nem veszik figyelembe az ünnepnapok és a különleges piaci kiigazítások hatásait.。",
			p11: "Konkrét kereskedési időre vonatkozó ajánlások",
			p12: "Bejelentkezési platform",
			p13: "，Nézze meg az érintett fajták profilját.。",
		},
		forecast: {
			tit: "Kereskedési kilátások",
			p1: "Átlagérték",
			p2: "Bikapiac (az árak emelkedni látszanak)",
			p3: "Bearish",
			p4: "Keresztáramok",
			p5: "Orientációk",
		},
		news: {
			tit: "Valós idejű hírek",
		},
		platforms: {
			tit: "HKDFINANC kereskedési platform",
			p: "Egyszerű és intuitív felület, kiváló kereskedési teljesítmény, több befektető igényeinek kielégítése. Kezdje el a kereskedést a HKDFINANC-nal még ma.",
			tit1: "Kényelmes és könnyen használható HKDFINANC kereskedési platform",
			tit2: "Kereskedjen bármikor, bárhol a HKDFINANC alkalmazással!",
			li1: "Kereskedjen több száz népszerű piacon hiteles szabályozással a pénzeszközök biztonságának biztosítása érdekében.",
			li2: "Figyelje a piaci trendeket valós időben, tekintse meg a kereskedési elemzéseket, és böngésszen a legfrissebb pénzügyi hírek között.",
			li3: "Bármikor megtekintheti a különböző típusú kereskedési grafikonokat, és mindenféle gond nélkül alkalmazhatja a grafikonkészítési funkciókat.",
			li4: "Tapasztalja meg a HKDFINANC díjnyertes kereskedési platformját Android vagy iOS eszközén!",
			code_download: "Kód beolvasása a letöltéshez",
			tit3: "Nincs szükség letöltésre, kereskedjen online a böngészőjén keresztül",
			li5: "Nincs szükség letöltésre, kereskedjen online közvetlenül a számítógépes böngészőjéből.",
			li6: "Díjnyertes: Legjobb Forex bróker Ázsiában 2022, Legjobb kereskedési platform Ausztráliában 2022, Leggyorsabban növekvő Forex Fintech bróker a világon 2022",
			li7: "Erőteljes technikai indikátorokat biztosít: MACD, KDJ, RSI, TRIX, DMA, CCI stb.",
			li8: "Kockázatkezelési eszközökkel, például stop-loss és negatív egyenlegvédelemmel felszerelve a kereskedések potenciális kockázatának mérséklése érdekében",
			tit4: "A tökéletes megoldás az asztali tranzakciókhoz",
			li9: "Könnyen megtekintheti a piaci árfolyamokat a saját maga által kiválasztott listában",
			li10: "Értesítések engedélyezése az árváltozási és egyéb információs értesítések fogadásához",
			li11: "A többféle diagram elrendezés lehetővé teszi, hogy különböző idődimenziókból kövesse a piacot.",
			li12: "Szerződésspecifikáció, kereskedési hangulat és kereskedési elemzés egy felületen, nincs szükség váltásra",
			li13: "Ismerje meg a legfrissebb kereskedelmi híreket közvetlenül a HKDFINANC-ban!",
			tit5: "Miért válassza a HKDFINANC kereskedési platformot?",
			row5_p1: "Biztonság",
			row5_p2: "0% jutalék",
			row5_p3: "Multi-platform támogatás",
			row5_p4: "Technikai diagram",
			row5_p5: "Hozzáférés bármikor, bárhol",
			row5_p6: "Különböző rajzeszközök",
		},
		quotes: {
			tit: "Valós idejű árajánlatok",
			p1: "Azonnali jegyzéseket, piaci hangulatot és kapcsolódó híreket biztosít a pillanatnyi pénzügyi témákról.",
			hot: "Divatban",
		},
		risk: {
			tit: "Kockázatkezelés",
			p1: "Minden kereskedés kockázattal jár. A HKDFINANC által biztosított ingyenes kockázatkezelési eszközök használatával hatékonyan kezelheti kockázatát a piaci körülményektől függetlenül.",
			function_tit1: "Take Profit/Stop Loss funkció",
			function_tit2: "Utánfutó stop funkció",
			function_p1: "A nyereség rögzítése",
			function_p2: "A veszteségek korlátozása",
			function_p3: "A nyereség maximalizálása",
			function_p4: "Nincs szükség a pozíciók automatikus ellenőrzésére",
			function_p5: "A veszteséglezárási ár kiigazítása",
			row2_tit1: "Take Profit/Stop Loss funkció",
			row2_p1: "Új megbízás létrehozásakor vagy egy meglévő megbízás módosításakor lehetőség van a 'Take Profit' és a 'Stop Loss' beállítására. Ha beállította, a megbízás általában az Ön által meghatározott célárfolyamon záródik, segítve Önt abban, hogy a cél elérésekor rögzítse nyereségét, vagy csökkentse veszteségét, ha a piac Ön ellen mozog. Felhívjuk figyelmét, hogy a piaci volatilitás miatt bármely megbízás shortolható, ebben az esetben a rendszer nem fogja tudni a megbízást az alapértelmezett áron végrehajtani, hanem a célárból a következő legkedvezőbb áron fogja lezárni a pozícióját.",
			row2_tit2: "Példák",
			row2_p2: "Az EUR/USD jelenlegi ára 1.13816/1.13837 (Eladás/Vétel). Ön 1 lot (1 lot = 100.000 EUR) vételi megbízást nyit 1,13837-nél, és 1,13806-nál állítja be a Stop Loss-ot.",
			row2_p3: "Általánosságban, amikor az árfolyam 1,13806-ra esik, a stop loss megbízás aktiválódik, és a pozíciót 1,13806-nál zárjuk, 31 $ kombinált veszteséggel.",
			row2_p4: "Amikor azonban a piaci feltételek megváltoznak, és az árfolyam közvetlenül 1,13837-ről 1,13795-re csúszik, kihagyva az Ön Stop Loss célját, a piac megugrik, és a rendszer nem lesz képes lezárni a pozíciót 1,13806-on, hanem a következő legkedvezőbb áron, azaz 1,13795-nél zárja a pozíciót az Ön számára, és a végső veszteség 42 USD lesz.",
			row3_tit1: "Utánfutó stop funkció",
			row3_tit2: "Példák",
			row3_p1: "A követő stopok (más néven trailing stopok) egy hatékony funkció, amely lehetővé teszi a nyereség rögzítését vagy a veszteségek minimalizálását anélkül, hogy folyamatosan figyelemmel kellene kísérnie pozícióit. Amikor megbízást hoz létre, egyszerűen beállít egy követő stopot, és amikor az ár az Ön javára mozog, a stop megbízása automatikusan frissül a legfrissebb árral. Ezzel szemben, amikor az ár az Ön ellenében mozog, a stop loss megbízás aktiválódik, és a pozíciót az Ön által beállított veszteségtávolsággal zárja. Felhívjuk figyelmét, hogy a piaci volatilitás miatt bármely megbízás shortolható, ebben az esetben a rendszer nem fogja tudni végrehajtani a megbízást az Ön alapértelmezett árfolyamán, hanem a pozíciót a célárfolyamból következő legkedvezőbb áron fogja lezárni az Ön számára.",
			row3_p2: "Az EUR/USD jelenlegi árfolyama 1,13816/1,13837 (eladás/vásárlás). Ön 1 tételes vételi megbízást hozott létre 1,13837-nél, 100 pipes (0,00100) követő stop veszteséggel.",
			row3_p3: "Ha a termék ára 1,13816-on áll, a Stop Loss értéke 1,13716. Ha a termék eladási ára 1,13845-re emelkedik, a Stop Loss frissül a beállított távolsággal, és a frissített Stop Loss értéke 1,13745 lesz.",
			row3_p4: "Ellenkezőleg, amikor a termék ára 1,13845-ről 1,13745-re csúszik, a követő stop aktiválódik, és a pozíciót 1,13745-nél zárjuk.",
			tip: "Pont: Általában egy pénzügyi eszköz árfolyamváltozásának legkisebb egységét nevezik pontnak. A HKDFINANC platformon ez az eszköz árának utolsó számjegyére vagy tizedesjegyére utal.",
		},
		sentiment: {
			tit: "Érzelmi index",
			type1: "Teljes",
			type2: "Deviza (valuta) átváltás",
			type3: "Áru",
			type4: "Exponents",
			long: "Hosszú távú (finanszírozás)",
			short: "Rövid (pénzügy)",
		},
	},
	layout: {
		aside: {
			nav1: "Ügyletek",
			nav2: "A jelenlegi piaci helyzet",
			nav3: "Információ",
			nav4: "Főiskola",
			nav5: "Az én",
			newsDialog: {
				title: "Hírközpont",
				type1: "Rendszerértesítés",
				type2: "Hírlevél",
				allRead: "Jelöljön meg mindent olvasottként",
			},
			settingDialog: {
				title: "Felállítani",
				nav1: "Hagyományos (fegyverek)",
				nav2: "Beállítások megjelenítése",
				nav3: "Rendszerinformáció",
				logout: "Kijelentkezés",
				setting1: "Többnyelvűség",
				setting2: "Ügyletek",
				setting2_tip: "A nyitott pozíciók automatikusan hozzáadják a saját maguk által kiválasztott fajtákat",
				setting3: "Információk visszaküldése",
				setting3_tip: "Funkcionális ajánlások",
				setting4: "Valós neves hitelesítés",
				setting4_tip: "Valós neves hitelesítés",
				setting5: "Téma mód",
				setting5_label1: "Homályosan",
				setting5_label2: "Élénk szín",
				setting6: "Emelkedő vagy csökkenő színek",
				setting6_label1: "Zöld emelkedik és piros esik",
				setting6_label2: "Lit. piros emelkedik, zöld esik",
				setting7: "Diagram beállítások",
				setting7_label1: "Standard kiadás",
				setting7_label2: "TradingView kiadás",
				setting8: "Nyilatkozatok és megállapodások",
				setting8_label1: "Adatvédelmi politika",
				setting8_label2: "Termék közzétételi nyilatkozat",
				setting8_label3: "Kockázati közzétételi nyilatkozat",
				setting8_label4: "Ügyfélmegállapodás",
				feedback_title: "Funkcionális ajánlások",
			}
		},
		footer: {
			hot: "Népszerű fajták",
			sort: "Választék",
			code: "Kódolás",
			buy_price: "Vételár",
			sell_price: "Eladói ár",
			chg: "Az ár emelkedése vagy csökkenése",
		},
		header: {
			place_search: "Kereskedelmi fajták keresése",
			balance: "Rendelkezésre álló egyenleg",
			balance_tip: "Rendelkezésre álló egyenleg: a számlán lévő pénzösszeg, amely új pozíciók nyitására használható.",
			profit_loss: "Növekvő és csökkenő",
			asset_view: "Az eszközök áttekintése",
			netValue: "Nettó érték",
			netValue_tip: "Nettó érték: folyószámla-érték, beleértve az összes pozíción keletkezett nyereséget és veszteséget.",
			marginLevel: "Margin szint",
			margin: "Letét (származtatott ügyleteknél)",
			margin_tip: "Margin: a devizapozíció megnyitásához és fenntartásához szükséges összeg.",
			maintainsMargin: "Karbantartási különbözet",
			maintainsMargin_tip: "Karbantartási biztosíték: Az a minimális összeg, amelyet a számláján fenn kell tartani, amíg az összes pozícióját tartja.",

		},
	},
	table: {
		label_name: "Név (egy személy vagy dolog neve)",
		label_buyPrice: "Ajánlati ár",
		label_sellPrice: "Eladási árfolyam",
		label_variableValue: "A változás értéke",
		trade: "Ügyletek",
		label_code: "Termék kód",
		label_title: "Név (egy dolog neve)",
		label_start: "Jelentőség",
		label_country: "Nemzetek",
		label_event: "Esemény",
		label_previous: "Korábbi érték",
		label_predictive: "Tervezett érték",
		label_announced: "Közzétett érték",
		label_stop_loss_min: "Minimális távolság a stop loss megbízásokhoz",
		label_difference: "Dinamikus terjedés",
		label_contract_size: "Szerződés mérete",
		label_max_hand: "Maximális tételméret egyetlen tranzakcióhoz",
		label_min_hand: "Minimális telekméret",
		label_trade_week: "Heti kereskedelmi naptár",
		label_week: "1 Hét",
		label_month: "1 Január",
		label_quarter: "1 Szezon (sport)",
		label_dailyTrend: "Egy nap trend",
		label_tradeId: "Tranzakció azonosító",
		label_breed: "Választék",
		label_tradeType: "A tranzakció típusa",
		label_currency: "Valuta",
		label_amount: "Pénzösszeg",
		label_balance: "Egyenlegek",
		label_type: "Tipológia",
		label_time: "Times",
		label_orderNumber: "Rendelési szám",
		label_pointer_number: "Sorszám",
		label_price_money: "Nyitóár",
		label_stop_win_price: "Profitrealizálás",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Nyitvatartási idő",
		label_profit_loss: "Növekvő és csökkenő",
		label_sell_buy: "Kikiáltási/vételi ár",
		label_chg: "Az ár emelkedése vagy csökkenése",
		label_sell_price: "Eladói ár",
		label_buy_price: "Vételár",
		label_condition: "Előfeltétel",
	},
	form: {
		label_feedback: "Kérdések és ajánlások",
		place_feedback: "Kérjük, írja be kérdését vagy javaslatát",
		label_img: "Fénykép",
		label_img_tip: "Nem kötelező, küldjön képernyőképet a problémáról",
		feedback_tip: "Ha sürgős kérdése van, kérjük, forduljon a következő címre",
		online: "Online ügyfélszolgálat",
		label_name: "Név és vezetéknév",
		place_name: "Kérjük, adja meg a nevét",
		label_email: "E-mail cím",
		place_email: "E-mail cím",
		message_email: "Kérjük, adja meg az e-mail címét",
		label_nationality: "Állampolgárság",
		place_nationality: "Kérjük, adja meg állampolgárságát",
		place_questionType: "A probléma típusa",
		message_questionType: "Kérjük, válassza ki a típust",
		questionType0: "Kereskedési platform",
		questionType1: "Termékek és díjak",
		questionType2: "Iratkozzon fel a napi piaci elemzésekre",
		questionType3: "Ügyfélszolgálat",
		questionType4: "A többi",
		place_question: "Az Ön kérdése",
		message_question: "Kérjük, adjon meg egy kérdést",
		submit_question: "A kérdések benyújtása",
		label_phone: "Mobiltelefonszám",
		place_phone: "Kérjük, adja meg mobiltelefonszámát",
		message_phone: "Kérjük, adja meg mobiltelefonszámát",
		label_password: "Kriptográfiai",
		place_password: "Kérjük, adja meg jelszavát",
		message_password: "Kérjük, adja meg jelszavát",
		label_confirmPassword: "Jelszó megerősítése",
		place_confirmPassword: "Kérjük, adja meg újra a jelszavát",
		message_confirmPassword: "Kérjük, adja meg újra a jelszavát",
		label_captcha: "CAPTCHA, egyfajta kihívás-válasz teszt (számítástechnika)",
		place_captcha: "Kérjük, adja meg az ellenőrző kódot",
		message_captcha: "Kérjük, adja meg az ellenőrző kódot",
		get_captcha: "CAPTCHA beszerzése",
		label_inviteId: "Meghívó kód",
		place_inviteId: "Meghívó kód (nem kötelező)",
		to: "Amíg",
		start_time: "Indulási idő",
		end_time: "Befejezési idő",
		label_cardNumber: "Bizonyítvány száma",
		place_cardNumber: "Kérjük, adja meg azonosítószámát",
		message_cardNumber: "Kérjük, adja meg azonosítószámát",
		label_cardMain: "A dokumentum eleje",
		message_cardMain: "Kérjük, adja meg a dokumentum elejét",
		label_cardBack: "Egy dokumentum hátoldala",
		message_cardBack: "Kérjük, adja meg a dokumentum hátoldalát",
		confirm_modify: "A változások megerősítése",
		label_realName: "Teljes név",
		place_realName: "Kérjük, adja meg a nevét",
		message_realName: "Kérjük, adja meg a teljes nevét",
		label_firstName: "Vezetéknév",
		place_firstName: "Kérjük, adja meg a vezetéknevét",
		message_firstName: "Kérjük, adja meg a vezetéknevét",
		label_lastName: "Név (egy dolog neve)",
		place_lastName: "Kérjük, adja meg a középső nevét",
		message_lastName: "Kérjük, adjon meg egy nevet",
		label_birthday: "Születési dátum",
		place_birthday: "Kérjük, adja meg születési dátumát",
		message_birthday: "Kérjük, adja meg születési dátumát",
		place_nowPsd: "Kérjük, adja meg jelenlegi jelszavát",
		message_nowPsd: "Kérjük, adja meg jelenlegi jelszavát",
		place_newPsd: "Kérjük, adjon meg egy új jelszót",
		message_newPsd: "Kérjük, adjon meg egy új jelszót",
		place_crmPsd: "Kérjük, erősítse meg az új jelszót",
		message_crmPsd: "Kérjük, erősítse meg az új jelszót",
		label_breed: "Választék",
		place_breed: "Kérjük, adjon meg egy fajt",
		label_phase: "(Koll.) megbukik (egy diák)",
		label_buyPrice: "Vételár",
		label_sellPrice: "Eladói ár",
		label_do: "Mert",
		label_height: "Nagyobb, mint",
		label_low: "Legyen alacsonyabb, mint",
		label_equal: "Vagy egyenlő",
		labelWalletName: "Pénztárca neve",
		messageWalletName: "Kérjük, adja meg a pénztárca nevét",
		placeWalletName: "Kérjük, adja meg a pénztárca nevét",
		labelWalletAddress: "Pénztárca cím",
		messageWalletAddress: "Kérjük, adja meg a pénztárca címét",
		placeWalletAddress: "Kérjük, adja meg a pénztárca címét",
		labelAmount: "Pénzösszeg",
		messageAmount: "Kérjük, adja meg az összeget",
		placeAmount: "Kérjük, adja meg az összeget",
		placeTimeLimit: "Kérjük, adjon meg egy időkorlátot",
		messageTimeLimit: "Kérjük, adjon meg egy időkorlátot",
	},
	pay: {
		title: "Könnyű hozzáférés a pénzeszközökhöz",
		p1: "Számos általánosan használt fizetési csatorna, gyors befizetés és kifizetés támogatása多种常用支付渠道，支持快速出入金",
		p2: "Előfordulhat, hogy egyes fizetési módok nem állnak rendelkezésre az Ön országában.",
		p3: "Üdvözöljük a HKDFINANC szolgáltatásainak megtapasztalásában",
		btn: "Nyisson számlát és kereskedjen most",
	},
	header: {
		tip1: "A tőkeáttételes kereskedés összetett pénzügyi termék, amely a gyors veszteség magas kockázatával jár.",
		btn1: "Kereskedési platform",
		btn2: "Kijelentkezés",
		btn3: "Bejelentkezés",
		BigUint64Array: "Kereskedj most",
	},
	footer: {
		help: "Akar？",
		tip1: "Kövesse közösségünket",
		tip2: "Felhívjuk figyelmét, hogy a HKDFINANC nem hozott létre hivatalos Telegram-csoportot, és a HKDFINANC nevében létrehozott Telegram-csoport gyaníthatóan hamisítás.",
		tip3: "KOCKÁZATFIGYELMEZTETÉS: A kereskedés az összes pénzeszköz elvesztését eredményezheti. A tőzsdén kívüli származtatott ügyletek kereskedése nem mindenki számára alkalmas. Kérjük, hogy szolgáltatásaink igénybevétele előtt figyelmesen olvassa el jogi dokumentumainkat, és a kereskedés előtt győződjön meg arról, hogy teljes mértékben tisztában van a kockázatokkal. Ön valójában nem tulajdonosa vagy birtokosa a mögöttes eszközöknek.",
		tip4: "A HKDFINANC nem nyújt tanácsot, ajánlást vagy véleményt a vásárlásról, tartásról vagy eladásról. Minden általunk kínált termék globális eszközökön alapuló tőzsdén kívüli származtatott ügylet. a HKDFINANC által nyújtott valamennyi szolgáltatás kizárólag a kereskedelmi megbízások végrehajtásán alapul.",
		tip5: "A HKDFINANC egy olyan üzleti márka, amelyet több vállalat oszt meg, és a következő vállalatokon keresztül működtet:",
		tip6: "A HKDFINANC International Ltd a jelen weboldalon ismertetett vagy felhasználható pénzügyi termékek kibocsátója. A HKDFINANC International Ltd-t a mauritiusi Pénzügyi Szolgáltatások Bizottsága (FSC) engedélyezte és szabályozza GB20025791 engedélyszámmal, székhelye a St Denis 6. szám alatt található. Street, 1st Floor River Court, Port Louis 11328, Mauritius, Mauritius",
		tip7: "A HKDFINANC Global Pty Ltd. az ABN 90 149 011 361 szám alatt van bejegyezve, az ausztrál pénzügyi szolgáltatási engedély (AFSL) száma 398528.",
		tip8: "A HKDFINANC Holding Ltd-t a Kajmán-szigeteki Monetáris Hatóság (CIMA) engedélyezte és szabályozza a 1612446 számú SIB-engedéllyel.",
		tip9: "A jelen weboldalon található információkat nem az Amerikai Egyesült Államok, Kanada, Japán vagy Új-Zéland lakosainak szánjuk, és nem szánjuk olyan országok vagy joghatóságok lakosainak, ahol a közzététel vagy felhasználás ellentétes lenne a helyi törvényekkel vagy szabályozásokkal. Felhívjuk figyelmét, hogy szolgáltatásaink elsődleges nyelve az angol, és ez az összes szerződéses dokumentumunk jogilag kötelező érvényű nyelve. A más nyelvekre történő fordítások csak referenciaként szolgálnak, és az angol és a kínai változat közötti bármilyen eltérés esetén az angol nyelvű változat az irányadó.",
		tip10: "SSL biztonságos kommunikációs titkosítás. Szerzői jog © HKDFINANC, Minden jog fenntartva.",
		link1: "Adatvédelmi politika",
		link2: "Termék közzétételi nyilatkozat",
		link3: "Panaszolási eljárás",
		link4: "Kockázati közzétételi nyilatkozat",
		link5: "Ügyfélmegállapodás",
		toTop: "Ragasztó (egy internetes fórumtéma stb.)",
	},
	nav: {
		tit1: "Ajánlatok",
		tit2: "Ügyletek",
		tit3: "Befektetési tanulás",
		tit4: "Rólunk",
		nav1: "Deviza (valuta) átváltás",
		nav2: "Exponents",
		nav3: "Áru",
		nav4: "Készlet (piac)",
		nav5: "Kereskedési platform",
		nav6: "Kereskedési stratégia",
		nav7: "Kereskedési kilátások",
		nav8: "Pénzügyi naptár",
		nav9: "Valós idejű hírek",
		nav10: "Valós idejű árajánlatok",
		nav11: "Érzelmi index",
		nav12: "Kockázatkezelés",
		nav13: "Szerződéses előírások",
		nav14: "Bevezetés a befektetésbe",
		nav15: "Befektetési betekintés",
		nav16: "Academy",
		nav17: "A HKDFINANC-ról",
		nav18: "Díjak és kitüntetések",
		nav19: "Médiaközpont",
		nav20: "Pénzügyi biztonság",
		nav21: "Díjak és illetékek",
		nav22: "Affiliates",
		nav23: "Kapcsolatfelvétel",
		nav24: "Gyakori problémák",
		nav25: "Súgóközpont",
	},

	city: {
		albania: "Albánia",
		algeria: "Algéria",
		argentina: "Argentína",
		armenia: "Örményország",
		australia: "Ausztrália",
		pakistan: "Pakisztán",
		austria: "Osztrák",
		bahrain: "Bahrein",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosznia-Hercegovina rövidítése",
		brazil: "Brazil",
		brunei: "Brunei Darussalam, független szultánság Borneó északnyugati részén.",
		bulgaria: "Bulgária",
		cambodia: "Kambodzsai",
		canada: "Kanadai",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Horvát Köztársaság (1991-)",
		cyprus: "Ciprus",
		czech_Republic: "Cseh Köztársaság (1993-tól)",
		denmark: "Dánia",
		dominican_Republic: "Dominikai Köztársaság",
		egypt: "Egyiptom",
		estonia: "Észtország",
		ethiopia: "Etiópia",
		finland: "Suomi",
		france: "Francia",
		georgia: "Grúzia (ország)",
		germany: "Német",
		ghana: "Ghána, Nyugat-Afrika",
		greece: "Görögország",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kína",
		hungary: "Magyarország",
		iceland: "Izlandi",
		ireland: "Ír",
		italy: "Olaszország",
		india: "India",
		indonesia: "Indonézia",
		israel: "Izraeli",
		iran: "Iráni",
		iraq: "Iraki",
		japan: "Japán",
		kazakstan: "Kazahsztán",
		kenya: "Kenya",
		korea: "Dél-Korea (Koreai Köztársaság)",
		kuwait: "Kuvaiti",
		kyrgyzstan: "Kirgizisztán",
		laos: "Laosz",
		latvia: "Lettország",
		lithuania: "A litván köztársaság, volt balti szovjet köztársaság",
		luxembourg: "Luxemburg",
		macao_China: "Makaó, Kína",
		macedonia: "Macedóniai",
		malaysia: "Malajzia",
		malta: "Máltai",
		mexico: "Mexikó",
		moldova: "Moldovai Köztársaság, volt szovjet köztársaság a romániai határon.",
		monaco: "Monaco",
		mongolia: "Mongólia",
		montenegro: "Montenegró",
		morocco: "Marokkó",
		myanmar: "Mianmar (vagy Burma)",
		netherlands: "Hollandia",
		new_Zealand: "Új-Zéland",
		nepal: "Nepáli",
		nigeria: "Nigéria, Nyugat-Afrika",
		norway: "Norvégia",
		oman: "Ománi",
		palestine: "Palesztina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perui",
		philippines: "Fülöp-szigeteki",
		poland: "Lengyel",
		portugal: "Portugália",
		puerto_Rico: "Puerto Rico, az Egyesült Államok nem bejegyzett, önkormányzattal rendelkező területe.",
		qatar: "Doha",
		romania: "Románia",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad és Tobago",
		rwanda: "Ruandai",
		saudi_Arabia: "Szaúd-Arábia",
		serbia: "Szerbia",
		singapore: "Szingapúri",
		slovakia: "Szlovákia",
		slovenia: "Szlovénia",
		south_Africa: "Dél-Afrika",
		spain: "Spanyol",
		sri_Lanka: "(Korábban) Ceylon",
		sweden: "Svédország",
		switzerland: "Svájc",
		taiwan_China: "Kína-Tajvan",
		tajikistan: "Tádzsikisztán",
		tanzania: "Tanzánia",
		thailand: "Thaiföld",
		turkey: "Isztambul",
		turkmenistan: "Türkmenisztán",
		ukraine: "Fehéroroszország",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Nagy-Britannia és Észak-Írország Egyesült Királysága",
		united_States: "Amerikai Egyesült Államok",
		uzbekistan: "Üzbegisztán",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganisztán",
		angola: "Angola",
		azerbaijan: "Azerbajdzsán, volt szovjet köztársaság és régió Irán északnyugati részén, a Kaukázusban.",
		bangladesh: "Banglades (korábban Kelet-Pakisztán)",
		belarus: "Fehéroroszország",
		belize: "Belizei",
		benin: "Benin",
		bhutan: "Bhután",
		bolivia: "Bolívia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brit Virgin-szigetek",
		burkina_Faso: "Burkina Faso, Nyugat-Afrika",
		burundi: "Burundi",
		cape_Verde: "Zöld-foki-szigetek",
		cayman_Islands: "Kajmán-szigetek",
		central_African_Republic: "Közép-afrikai Köztársaság",
		chad: "Csádi",
		comoros: "Comore-szigetek",
		the_Republic_of_the_Congo: "Kongó (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongó (Demokratikus Köztársaság)",
		djibouti: "Dzsibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Egyenlítői-Guinea",
		eritrea: "Eritrea",
		fiji: "Fidzsi-szigetek",
		gabon: "Gaboni",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti, a karibi Hispaniola sziget nyugati harmada.",
		isle_of_Man: "Mann-sziget",
		cote_d_Ivoire: "Elefántcsontpart vagy Elefántcsontpart Nyugat-Afrikában",
		jamaica: "Jamaikai",
		jordan: "Jordan",
		lebanon: "Ellenségeskedések",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagaszkár",
		malawi: "Malawi",
		maldives: "A Maldív-szigetek",
		mali: "Mali, Nyugat-Afrika",
		mauritania: "Mauritánia",
		mauritius: "Mauritius",
		mozambique: "Mozambik",
		namibia: "Namíbia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (afrikai állam)",
		north_Korea: "Koreai Csoszun vagy Csoszun dinasztia 1392-1910",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Szenegál",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Szultán ",
		suriname: "Suriname",
		eswatini: "Szváziföld",
		syria: "Szíria",
		togo: "Togo, Nyugat-Afrika",
		tonga: "Tonga, dél-csendes-óceáni szigetvilág királysága",
		tunisia: "Tunisz, Tunézia fővárosa",
		united_States_Virgin_Islands: "Egyesült Államok Virgin-szigetek (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikán",
		yemen: "Jemeni",
		yugoslavia: "Jugoszlávia, 1943-1992",
		zambia: "Seychelle-szigetek",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
